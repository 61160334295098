import { useCallback, useReducer } from 'react';
import { useBlock } from './TRPC_Hooks/useBlock';
import { useShowToast } from './useShowToast';
interface IBlockState {
  isBlockModalVisible: boolean;
  isSuccessModalVisible: boolean;
}
type BlockAction = {
  type: 'SHOW_BLOCK_MODAL';
} | {
  type: 'SHOW_SUCCESS_MODAL';
} | {
  type: 'SET_STATE';
  payload: IBlockState;
} | {
  type: 'COMPLETE';
};
interface IUseBlockFlowProps {
  targetShortId: string;
  onComplete?: () => void;
  onClose?: () => void;
}
const initialState: IBlockState = {
  isBlockModalVisible: true,
  isSuccessModalVisible: false
};
function blockReducer(state: IBlockState, action: BlockAction): IBlockState {
  switch (action.type) {
    case 'SHOW_BLOCK_MODAL':
      return {
        ...state,
        isSuccessModalVisible: false,
        isBlockModalVisible: true
      };
    case 'SHOW_SUCCESS_MODAL':
      return {
        ...state,
        isBlockModalVisible: false,
        isSuccessModalVisible: true
      };
    case 'COMPLETE':
      return {
        ...initialState
      };
    default:
      return state;
  }
}
export const useBlockFlow = ({
  onClose,
  targetShortId
}: IUseBlockFlowProps) => {
  const [state, dispatch] = useReducer(blockReducer, initialState);
  const {
    showToast
  } = useShowToast();
  const {
    blockUser
  } = useBlock();
  const hideModals = useCallback(() => {
    onClose?.();
  }, [onClose]);
  const showSuccessModal = useCallback(() => {
    dispatch({
      type: 'SHOW_SUCCESS_MODAL'
    });
  }, []);
  const handleBlock = useCallback(async () => {
    try {
      await blockUser({
        targetShortId: targetShortId
      });
      hideModals();
    } catch (error) {
      showToast({
        variant: 'error',
        text: 'error.default'
      });
    }
  }, [targetShortId, showToast, blockUser, hideModals]);
  return {
    ...state,
    hideModals,
    handleBlock,
    showSuccessModal
  };
};
import { useTranslation } from '@hooks/useTranslation';
import s from './blockProfile.module.scss';
import { Button } from '@components/UIKit/Button/Button';
import { Divider } from '@components/UIKit/Divider/Divider';
interface ISuccessStepProps {
  onComplete: () => void;
  authorName: string;
}
export const SuccessStep = ({
  onComplete,
  authorName
}: ISuccessStepProps) => {
  const {
    translate: tr
  } = useTranslation();
  return <div className={s.container} data-sentry-component="SuccessStep" data-sentry-source-file="SuccessStep.tsx">
			<div className={s.container__header}>
				<h3 className={s.container__header_title}>
					{tr({
          id: 'block.success.title'
        })}
				</h3>
			</div>

			<p className={s.container__text}>
				{tr({
        id: 'block.success.description'
      }, {
        author: authorName
      })}
			</p>

			<Divider data-sentry-element="Divider" data-sentry-source-file="SuccessStep.tsx" />

			<div className={s.container__buttons}>
				<Button id='default_button' onClick={onComplete} color='Black' size='medium' data-sentry-element="Button" data-sentry-source-file="SuccessStep.tsx">
					{tr({
          id: 'btn.ok'
        })}
				</Button>
			</div>
		</div>;
};
import { SVGProps } from "react";
import { Heart } from "./Icons/Heart";
import { NeutralFace } from "./Icons/NeutralFace";
import { Fire } from "./Icons/Fire";
import { PoutingFace } from "./Icons/PoutingFace";
import { PostReactionType } from "@prisma/client";
export const enum EmojiTypes {
  'emoji_fire' = 'emoji_fire',
  'emoji_heart' = 'emoji_heart',
  'emoji_neutral_face' = 'emoji_neutral_face',
  'emoji_pouting_face' = 'emoji_pouting_face',
}
export const EmojiIconTypeDictionary: Record<PostReactionType, EmojiTypes> = {
  [PostReactionType.FIRE]: EmojiTypes.emoji_fire,
  [PostReactionType.HEART]: EmojiTypes.emoji_heart,
  [PostReactionType.NEUTURAL_FACE]: EmojiTypes.emoji_neutral_face,
  [PostReactionType.POUTING_FACE]: EmojiTypes.emoji_pouting_face
};
export const EmojiIcon = {
  [EmojiTypes.emoji_fire]: (props: SVGProps<SVGSVGElement>) => <Fire {...props} />,
  [EmojiTypes.emoji_heart]: (props: SVGProps<SVGSVGElement>) => <Heart {...props} />,
  [EmojiTypes.emoji_neutral_face]: (props: SVGProps<SVGSVGElement>) => <NeutralFace {...props} />,
  [EmojiTypes.emoji_pouting_face]: (props: SVGProps<SVGSVGElement>) => <PoutingFace {...props} />
};
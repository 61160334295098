import { SpriteIcon } from '@components/Sprites/SpriteIcon';
import { Button } from '@components/UIKit/Button/Button';
import { Checkbox } from '@components/UIKit/Checkbox/Checkbox';
import { Divider } from '@components/UIKit/Divider/Divider';
import { useTranslation } from '@hooks/useTranslation';
import { BLOCK_VARIANTS } from '@specs/reports';
import { FC, useCallback, useState } from 'react';
import s from './reportFlow.module.scss';
interface Props {
  isBlock: boolean | null;
  onSetBlock: (isBlock: boolean) => void;
  onSubmit: () => Promise<void>;
  onCancel: () => void;
  isPost?: boolean;
}
export const BlockStep: FC<Props> = ({
  isBlock,
  onSetBlock,
  onSubmit,
  onCancel,
  isPost
}) => {
  const {
    translate: tr
  } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const handleSubmit = useCallback(async () => {
    try {
      setIsLoading(true);
      await onSubmit();
    } finally {
      setIsLoading(false);
    }
  }, [onSubmit, isLoading, setIsLoading]);
  return <div className={s.container} data-sentry-component="BlockStep" data-sentry-source-file="BlockStep.tsx">
			<div className={s.header}>
				<h3 className={s.header_title}>
					{tr({
          id: 'report.reason.title'
        })}
				</h3>
				<button className={s.header_close} onClick={onCancel}>
					<SpriteIcon name='close_small' width={32} height={32} style={{
          backgroundColor: '#F2F2F2',
          borderRadius: '50%',
          padding: 8
        }} data-sentry-element="SpriteIcon" data-sentry-source-file="BlockStep.tsx" />
				</button>
			</div>

			<div className={s.body}>
				<h3 className={s.header_subtitle}>
					{tr({
          id: 'report.block.subtitle'
        })}
				</h3>

				<p className={s.body_text}>
					{tr({
          id: 'report.block.text'
        })}
				</p>

				<p className={s.body_question}>
					{tr({
          id: 'report.block.question'
        }, {
          target: isPost ? tr({
            id: 'report.target.post'
          }) : tr({
            id: 'report.target.author'
          })
        })}
				</p>

				<div className={s.body_list}>
					{BLOCK_VARIANTS.map(({
          id,
          value,
          langKey
        }) => <button key={id} className={s.body_list_item} onClick={() => onSetBlock(value)}>
							<Checkbox variant='circle' checked={isBlock === value} />
							{tr({
            id: langKey
          }, {
            target: isPost ? tr({
              id: 'report.target.post'
            }) : tr({
              id: 'report.target.author'
            })
          })}
						</button>)}
				</div>

				<Divider data-sentry-element="Divider" data-sentry-source-file="BlockStep.tsx" />

				<div className={s.body_buttons}>
					<Button id='default_button' color='Black' size='medium' disabled={isBlock === null || isLoading} loading={isLoading} onClick={handleSubmit} data-sentry-element="Button" data-sentry-source-file="BlockStep.tsx">
						{tr({
            id: 'btn.confirm'
          })}
					</Button>
					<Button id='default_button' color='Transparent' size='medium' onClick={onCancel} className={s.body_buttons_cancel} data-sentry-element="Button" data-sentry-source-file="BlockStep.tsx">
						{tr({
            id: 'btn.cancel'
          })}
					</Button>
				</div>
			</div>
		</div>;
};
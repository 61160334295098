import cn from 'classnames';
import dynamic from 'next/dynamic';
import { FC, useCallback, useRef, useState } from 'react';
import { SpriteIcon } from '@components/Sprites/SpriteIcon';
import { Button, ButtonProps } from '@components/UIKit/Button/Button';
import { post } from '@prisma/client';
import { copyHandler } from '@specs/helpers';
import { LinksEnum } from '@specs/navigation';
import { useRouter } from 'next/router';
import { useOnClickOutside } from '@hooks/useClickOutside';
import { useShowToast } from '@hooks/useShowToast';
import { useTranslation } from '@hooks/useTranslation';
import { BlockFlow } from '@components/Modals/Variants/BlockFlow/BlockFlow';
import { ReportFlow } from '@components/Modals/Variants/ReportFlow/ReportFlow';
import s from './postCard.module.scss';
const Modal = dynamic(import('@components/Modals/Modal').then(module => module.Modal), {
  ssr: false
});
const DeletePostModal = dynamic(import('@components/Modals/Variants/DeletePost/DeletePost').then(module => module.DeletePost), {
  ssr: false
});
type PostCardEditProps = {
  postId: post['id'];
  link: string;
  isPersonal: boolean;
  shortId: string;
};
const defaultButtonProps: Omit<ButtonProps, 'children' | 'id'> = {
  size: 'medium',
  color: 'Transparent'
};
export const PostCardEdit: FC<PostCardEditProps> = ({
  postId,
  link,
  isPersonal,
  shortId
}) => {
  const router = useRouter();
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const {
    translate: tr
  } = useTranslation();
  const actionsRef = useRef<HTMLDivElement>(null);
  const {
    showToast
  } = useShowToast();
  const closeHandler = useCallback(() => setIsOpen(false), []);
  useOnClickOutside(actionsRef, closeHandler);
  const closeDeleteModalHandler = () => setIsDeleteModalOpen(false);
  const openDeleteModalHandler = () => setIsDeleteModalOpen(true);
  const [isReportFlowOpen, setIsReportFlowOpen] = useState(false);
  const [isBlockModalOpen, setIsBlockModalOpen] = useState(false);
  const [isPost, setIsPost] = useState(false);
  const goToEdit = async () => {
    await router.push({
      pathname: LinksEnum.POST_CREATE.replace('[[...id]]', String(postId))
    });
  };
  const handleCopy = async () => {
    await copyHandler(link).then(_ => {
      showToast({
        variant: 'success',
        text: 'link.copied'
      });
      closeHandler();
    }).catch(_ => {
      showToast({
        variant: 'error'
      });
    });
  };
  const handleReportPost = () => {
    setIsPost(true);
    setIsReportFlowOpen(true);
  };
  const handleBlockModalClose = useCallback(() => {
    setIsBlockModalOpen(false);
    setIsPost(false);
  }, []);
  const handleReportFlowClose = useCallback(() => {
    setIsReportFlowOpen(false);
    setIsPost(false);
  }, []);
  return <div className={s.actions} ref={actionsRef} data-sentry-component="PostCardEdit" data-sentry-source-file="PostCardEdit.tsx">
            <BlockFlow isOpen={isBlockModalOpen} onClose={handleBlockModalClose} targetShortId={shortId} data-sentry-element="BlockFlow" data-sentry-source-file="PostCardEdit.tsx" />

            <ReportFlow isOpen={isReportFlowOpen} targetShortId={shortId} onClose={handleReportFlowClose} isPost={isPost} postId={postId} data-sentry-element="ReportFlow" data-sentry-source-file="PostCardEdit.tsx" />

            <SpriteIcon name='dots' className={s.actions__icon} onClick={() => setIsOpen(prev => !prev)} data-sentry-element="SpriteIcon" data-sentry-source-file="PostCardEdit.tsx" />

            <div className={cn(s.actions__list, isOpen && s.actions__list_open)}>
                <Button id='copy_link_to_post_button' {...defaultButtonProps} onClick={handleCopy} data-sentry-element="Button" data-sentry-source-file="PostCardEdit.tsx">
                    {tr({
          id: 'post.copy.link.to.post'
        })}
                </Button>
                {isPersonal ? <>
                            <Button id='edit_post_button' onClick={goToEdit} {...defaultButtonProps}>
                                {tr({
            id: 'post.edit.post'
          })}
                            </Button>

                            <Button id='delete_post_button' {...defaultButtonProps} onClick={openDeleteModalHandler} className={s.actions__icon_delete}>
                                {tr({
            id: 'post.delete.post.modal.title'
          })}
                            </Button>
                        </> : <>
                            <>
                                {/* <ActionWrapper
                                    condition={isAuthenticated}
                                 >
                                    <Button
                                        id='block_this_author_button'
                                        {...defaultButtonProps}
                                        onClick={() => setIsBlockModalOpen(true)}
                                    >
                                        {tr({ id: 'btn.block.this.author' })}
                                    </Button>
                                 </ActionWrapper> */}

                                {/* <ActionWrapper
                                    condition={isAuthenticated}
                                 >
                                    <Button
                                        id='profile_page_report_button'
                                        {...defaultButtonProps}
                                        onClick={() => setIsReportFlowOpen(true)}
                                    >
                                        {tr({ id: 'post.block.this.author' })}
                                    </Button>
                                 </ActionWrapper> */}

                                <Button id='profile_page_report_button' {...defaultButtonProps} onClick={handleReportPost}>
                                    {tr({
              id: 'post.block.this.post'
            })}
                                </Button>
                            </>
                        </>}
            </div>

            <Modal isOpen={isDeleteModalOpen} onClose={closeDeleteModalHandler} data-sentry-element="Modal" data-sentry-source-file="PostCardEdit.tsx">
                <DeletePostModal closeHandler={closeDeleteModalHandler} post_id={postId} data-sentry-element="DeletePostModal" data-sentry-source-file="PostCardEdit.tsx" />
            </Modal>
        </div>;
};
import { FC, SVGProps } from "react";
export const Fire: FC<SVGProps<SVGSVGElement>> = props => <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none" {...props} data-sentry-element="svg" data-sentry-component="Fire" data-sentry-source-file="Fire.tsx">
        <path d="M20.9899 14.0114C21.0738 9.79803 14.6738 10.3771 16.3195 3.04755C16.3195 3.04755 12.0224 4.73899 12.7767 9.92755C13.0357 11.7104 12.411 13.0437 12.411 13.0437C11.7786 13.1733 10.7957 11.9161 11.1386 9.97327C11.1386 9.97327 8.88334 11.6571 8.88334 13.8208C8.88334 15.9847 9.92714 18.278 9.92714 18.278C7.32905 17.7142 7.5881 14.2095 7.5881 14.2095C7.5881 14.2095 5.94238 16.4114 5.94238 20.5638C5.94238 23.7942 9.58429 28.9523 15.4586 28.9523C21.3329 28.9523 26.049 24.3962 26.049 20.3428C26.049 18.5675 25.4471 16.2742 24.1443 15.0247C24.1443 15.0247 24.3195 18.5675 22.5671 18.3999C22.5671 18.3999 24.0147 13.7752 22.4986 11.6571C22.4986 11.6571 21.7671 13.9428 20.9899 14.0114Z" fill="url(#paint0_linear_5513_112654)" data-sentry-element="path" data-sentry-source-file="Fire.tsx" />
        <path d="M20.9972 10.8345C22.7038 7.68788 18.4752 7.2536 18.4676 5.31836C18.4676 5.31836 17.721 7.31455 19.5039 8.67836C20.9591 9.78312 20.9972 10.8345 20.9972 10.8345Z" fill="url(#paint1_linear_5513_112654)" data-sentry-element="path" data-sentry-source-file="Fire.tsx" />
        <path d="M12.0227 4.81512C11.375 5.45512 12.2665 8.09894 10.2398 9.14274C10.2398 9.14274 9.55409 6.23988 12.0227 4.81512Z" fill="url(#paint2_linear_5513_112654)" data-sentry-element="path" data-sentry-source-file="Fire.tsx" />
        <path opacity="0.5" d="M7.27572 15.2684C6.7119 16.2361 5.94238 18.0265 5.94238 20.5637C5.94238 23.398 9.55381 28.6095 15.4586 28.6095C21.2262 28.6095 25.7062 24.1675 25.7062 20.3504C25.7062 18.9713 25.3253 17.2113 24.4795 15.9617C24.4186 16.7465 24.2281 17.8132 23.6414 18.3695C23.3366 18.6589 22.9557 18.7884 22.5366 18.7428L22.1099 18.7047L22.2395 18.3008C22.2547 18.2628 23.3595 14.6513 22.5595 12.5104C22.2395 13.2951 21.691 14.3084 21.0205 14.3617L20.6395 14.3923L20.6471 14.0113C20.6777 12.4342 19.7099 11.5961 18.5899 10.6285C16.9899 9.24179 15.1843 7.67227 15.8471 3.6875C14.7347 4.34274 12.571 6.14846 13.1119 9.87417C13.3862 11.7408 12.7386 13.1275 12.7157 13.1885L12.6395 13.3484L12.4719 13.3789C12.1519 13.4399 11.8167 13.3104 11.5119 13.0208C11.1005 12.617 10.7271 11.8551 10.7119 10.8189C10.0871 11.4589 9.2262 12.5637 9.2262 13.8208C9.2262 15.8856 10.2319 18.1104 10.2395 18.1332L10.5214 18.758L9.85096 18.6132C8.09857 18.2323 7.44334 16.6017 7.27572 15.2684Z" fill="url(#paint3_linear_5513_112654)" data-sentry-element="path" data-sentry-source-file="Fire.tsx" />
        <path d="M18.4677 5.31836C18.3153 6.46884 18.7725 8.12217 19.5039 8.67836C20.1744 9.18884 20.9287 10.065 20.9896 10.8345C22.1325 8.59455 20.5248 7.76408 19.6029 7.43645C19.2144 7.29931 18.4981 6.49931 18.4677 5.31836Z" fill="url(#paint4_radial_5513_112654)" data-sentry-element="path" data-sentry-source-file="Fire.tsx" />
        <path d="M14.9561 4.99091C12.4267 8.95281 15.7105 13.1357 14.0191 15.6423C12.3276 18.149 10.6133 15.8328 10.499 14.2632C10.499 14.2632 10.5143 19.3376 13.021 21.3414C15.5276 23.3452 8.19809 22.911 7.19238 20.4652C7.19238 20.4652 7.19238 28.6099 15.4591 28.6099C23.7257 28.6099 25.1048 21.029 25.1048 21.029C25.1048 21.029 22.0952 23.5966 20.0915 21.5928C20.0915 21.5928 21.5924 18.9643 21.9733 16.2671C21.9733 16.2671 20.9372 16.9223 19.3448 16.671C19.3448 16.671 20.2285 12.8842 18.4228 11.6423C16.1067 10.05 14.1485 6.25568 14.9561 4.99091Z" fill="url(#paint5_linear_5513_112654)" data-sentry-element="path" data-sentry-source-file="Fire.tsx" />
        <path d="M15.5895 9.65302C14.2638 11.3978 16.8467 15.8777 14.1343 17.6529C12.6333 18.6359 11.7724 17.4549 11.7724 17.4549C11.7724 17.4549 12.1762 19.2683 13.7914 20.8835C15.4067 22.4987 11.361 24.6853 8.07715 21.8587C8.07715 21.8587 9.20476 28.6473 16.8467 27.5044C25.281 26.2473 24.6562 19.9768 24.6562 19.9768C24.6562 19.9768 22.3704 23.5729 19.1171 21.8511C19.1171 21.8511 21.1667 18.994 21.1667 17.3559C21.1667 17.3559 19.78 18.1787 18.1952 17.3863C18.1952 17.3863 18.9495 13.5768 18.0504 13.0054C15.8943 11.6263 15.5895 9.65302 15.5895 9.65302Z" fill="url(#paint6_linear_5513_112654)" data-sentry-element="path" data-sentry-source-file="Fire.tsx" />
        <path opacity="0.5" d="M20.9899 14.0114C21.0738 9.79803 14.6738 10.3771 16.3195 3.04755C16.3195 3.04755 12.0224 4.73899 12.7767 9.92755C13.0357 11.7104 12.411 13.0437 12.411 13.0437C11.7786 13.1733 10.7957 11.9161 11.1386 9.97327C11.1386 9.97327 8.88334 11.6571 8.88334 13.8208C8.88334 15.9847 9.92714 18.278 9.92714 18.278C7.32905 17.7142 7.5881 14.2095 7.5881 14.2095C7.5881 14.2095 5.94238 16.4114 5.94238 20.5638C5.94238 23.7942 9.58429 28.9523 15.4586 28.9523C21.3329 28.9523 26.049 24.3962 26.049 20.3428C26.049 18.5675 25.4471 16.2742 24.1443 15.0247C24.1443 15.0247 24.3195 18.5675 22.5671 18.3999C22.5671 18.3999 24.0147 13.7752 22.4986 11.6571C22.4986 11.6571 21.7671 13.9428 20.9899 14.0114Z" fill="url(#paint7_radial_5513_112654)" data-sentry-element="path" data-sentry-source-file="Fire.tsx" />
        <path opacity="0.5" d="M20.9899 14.0114C21.0738 9.79803 14.6738 10.3771 16.3195 3.04755C16.3195 3.04755 12.0224 4.73899 12.7767 9.92755C13.0357 11.7104 12.411 13.0437 12.411 13.0437C11.7786 13.1733 10.7957 11.9161 11.1386 9.97327C11.1386 9.97327 8.88334 11.6571 8.88334 13.8208C8.88334 15.9847 9.92714 18.278 9.92714 18.278C7.32905 17.7142 7.5881 14.2095 7.5881 14.2095C7.5881 14.2095 5.94238 16.4114 5.94238 20.5638C5.94238 23.7942 9.58429 28.9523 15.4586 28.9523C21.3329 28.9523 26.049 24.3962 26.049 20.3428C26.049 18.5675 25.4471 16.2742 24.1443 15.0247C24.1443 15.0247 24.3195 18.5675 22.5671 18.3999C22.5671 18.3999 24.0147 13.7752 22.4986 11.6571C22.4986 11.6571 21.7671 13.9428 20.9899 14.0114Z" fill="url(#paint8_radial_5513_112654)" data-sentry-element="path" data-sentry-source-file="Fire.tsx" />
        <path d="M6.46094 18.7962C6.63618 23.1008 10.179 28.259 15.7181 28.259C21.2571 28.259 24.0228 22.4152 24.1981 18.9942C24.1981 18.9942 23.1619 21.0056 20.8228 21.4095C18.4837 21.8132 20.9828 17.3866 19.3448 14.7656C19.3448 14.7656 17.8971 19.3295 15.84 19.4971C13.7828 19.6647 12.0381 16.4799 12.0381 14.7656C12.0381 14.7656 10.7733 17.8514 11.0857 20.3047C11.5657 24.0304 6.46094 18.7962 6.46094 18.7962Z" fill="url(#paint9_radial_5513_112654)" data-sentry-element="path" data-sentry-source-file="Fire.tsx" />
        <path opacity="0.5" d="M20.9899 14.0114C21.0738 9.79803 14.6738 10.3771 16.3195 3.04755C16.3195 3.04755 12.0224 4.73899 12.7767 9.92755C13.0357 11.7104 12.411 13.0437 12.411 13.0437C11.7786 13.1733 10.7957 11.9161 11.1386 9.97327C11.1386 9.97327 8.88334 11.6571 8.88334 13.8208C8.88334 15.9847 9.92714 18.278 9.92714 18.278C7.32905 17.7142 7.5881 14.2095 7.5881 14.2095C7.5881 14.2095 5.94238 16.4114 5.94238 20.5638C5.94238 23.7942 9.58429 28.9523 15.4586 28.9523C21.3329 28.9523 26.049 24.3962 26.049 20.3428C26.049 18.5675 25.4471 16.2742 24.1443 15.0247C24.1443 15.0247 24.3195 18.5675 22.5671 18.3999C22.5671 18.3999 24.0147 13.7752 22.4986 11.6571C22.4986 11.6571 21.7671 13.9428 20.9899 14.0114Z" fill="url(#paint10_radial_5513_112654)" data-sentry-element="path" data-sentry-source-file="Fire.tsx" />
        <path opacity="0.5" d="M20.9972 10.8345C22.7038 7.68788 18.4752 7.2536 18.4676 5.31836C18.4676 5.31836 17.721 7.31455 19.5039 8.67836C20.9591 9.78312 20.9972 10.8345 20.9972 10.8345Z" fill="url(#paint11_radial_5513_112654)" data-sentry-element="path" data-sentry-source-file="Fire.tsx" />
        <path d="M12.0227 4.81512C11.3827 5.45512 11.7941 7.47416 10.2398 9.14274C10.2398 9.14274 9.55409 6.23988 12.0227 4.81512Z" fill="url(#paint12_radial_5513_112654)" data-sentry-element="path" data-sentry-source-file="Fire.tsx" />
        <path opacity="0.5" d="M12.0227 4.81512C11.375 5.45512 12.2665 8.09894 10.2398 9.14274C10.2398 9.14274 9.55409 6.23988 12.0227 4.81512Z" fill="url(#paint13_radial_5513_112654)" data-sentry-element="path" data-sentry-source-file="Fire.tsx" />
        <path opacity="0.5" d="M20.9899 14.0114C21.0738 9.79803 14.6738 10.3771 16.3195 3.04755C16.3195 3.04755 12.0224 4.73899 12.7767 9.92755C13.0357 11.7104 12.411 13.0437 12.411 13.0437C11.7786 13.1733 10.7957 11.9161 11.1386 9.97327C11.1386 9.97327 8.88334 11.6571 8.88334 13.8208C8.88334 15.9847 9.92714 18.278 9.92714 18.278C7.32905 17.7142 7.5881 14.2095 7.5881 14.2095C7.5881 14.2095 5.94238 16.4114 5.94238 20.5638C5.94238 23.7942 9.58429 28.9523 15.4586 28.9523C21.3329 28.9523 26.049 24.3962 26.049 20.3428C26.049 18.5675 25.4471 16.2742 24.1443 15.0247C24.1443 15.0247 24.3195 18.5675 22.5671 18.3999C22.5671 18.3999 24.0147 13.7752 22.4986 11.6571C22.4986 11.6571 21.7671 13.9428 20.9899 14.0114Z" fill="url(#paint14_radial_5513_112654)" data-sentry-element="path" data-sentry-source-file="Fire.tsx" />
        <path opacity="0.25" d="M20.9899 14.0114C21.0738 9.79803 14.6738 10.3771 16.3195 3.04755C16.3195 3.04755 12.0224 4.73899 12.7767 9.92755C13.0357 11.7104 12.411 13.0437 12.411 13.0437C11.7786 13.1733 10.7957 11.9161 11.1386 9.97327C11.1386 9.97327 8.88334 11.6571 8.88334 13.8208C8.88334 15.9847 9.92714 18.278 9.92714 18.278C7.32905 17.7142 7.5881 14.2095 7.5881 14.2095C7.5881 14.2095 5.94238 16.4114 5.94238 20.5638C5.94238 23.7942 9.58429 28.9523 15.4586 28.9523C21.3329 28.9523 26.049 24.3962 26.049 20.3428C26.049 18.5675 25.4471 16.2742 24.1443 15.0247C24.1443 15.0247 24.3195 18.5675 22.5671 18.3999C22.5671 18.3999 24.0147 13.7752 22.4986 11.6571C22.4986 11.6571 21.7671 13.9428 20.9899 14.0114Z" fill="url(#paint15_radial_5513_112654)" data-sentry-element="path" data-sentry-source-file="Fire.tsx" />
        <path opacity="0.25" d="M20.9899 14.0114C21.0738 9.79803 14.6738 10.3771 16.3195 3.04755C16.3195 3.04755 12.0224 4.73899 12.7767 9.92755C13.0357 11.7104 12.411 13.0437 12.411 13.0437C11.7786 13.1733 10.7957 11.9161 11.1386 9.97327C11.1386 9.97327 8.88334 11.6571 8.88334 13.8208C8.88334 15.9847 9.92714 18.278 9.92714 18.278C7.32905 17.7142 7.5881 14.2095 7.5881 14.2095C7.5881 14.2095 5.94238 16.4114 5.94238 20.5638C5.94238 23.7942 9.58429 28.9523 15.4586 28.9523C21.3329 28.9523 26.049 24.3962 26.049 20.3428C26.049 18.5675 25.4471 16.2742 24.1443 15.0247C24.1443 15.0247 24.3195 18.5675 22.5671 18.3999C22.5671 18.3999 24.0147 13.7752 22.4986 11.6571C22.4986 11.6571 21.7671 13.9428 20.9899 14.0114Z" fill="url(#paint16_radial_5513_112654)" data-sentry-element="path" data-sentry-source-file="Fire.tsx" />
        <path opacity="0.25" d="M20.9899 14.0114C21.0738 9.79803 14.6738 10.3771 16.3195 3.04755C16.3195 3.04755 12.0224 4.73899 12.7767 9.92755C13.0357 11.7104 12.411 13.0437 12.411 13.0437C11.7786 13.1733 10.7957 11.9161 11.1386 9.97327C11.1386 9.97327 8.88334 11.6571 8.88334 13.8208C8.88334 15.9847 9.92714 18.278 9.92714 18.278C7.32905 17.7142 7.5881 14.2095 7.5881 14.2095C7.5881 14.2095 5.94238 16.4114 5.94238 20.5638C5.94238 23.7942 9.58429 28.9523 15.4586 28.9523C21.3329 28.9523 26.049 24.3962 26.049 20.3428C26.049 18.5675 25.4471 16.2742 24.1443 15.0247C24.1443 15.0247 24.3195 18.5675 22.5671 18.3999C22.5671 18.3999 24.0147 13.7752 22.4986 11.6571C22.4986 11.6571 21.7671 13.9428 20.9899 14.0114Z" fill="url(#paint17_radial_5513_112654)" data-sentry-element="path" data-sentry-source-file="Fire.tsx" />
        <defs data-sentry-element="defs" data-sentry-source-file="Fire.tsx">
            <linearGradient id="paint0_linear_5513_112654" x1="17.2842" y1="29.6683" x2="12.6136" y2="-4.17943" gradientUnits="userSpaceOnUse" data-sentry-element="linearGradient" data-sentry-source-file="Fire.tsx">
                <stop stopColor="#FF0000" data-sentry-element="stop" data-sentry-source-file="Fire.tsx" />
                <stop offset="0.2317" stopColor="#FF1500" data-sentry-element="stop" data-sentry-source-file="Fire.tsx" />
                <stop offset="0.7367" stopColor="#FF3D00" data-sentry-element="stop" data-sentry-source-file="Fire.tsx" />
                <stop offset="0.9987" stopColor="#FF4C00" data-sentry-element="stop" data-sentry-source-file="Fire.tsx" />
            </linearGradient>
            <linearGradient id="paint1_linear_5513_112654" x1="22.7567" y1="28.9135" x2="18.086" y2="-4.93431" gradientUnits="userSpaceOnUse" data-sentry-element="linearGradient" data-sentry-source-file="Fire.tsx">
                <stop stopColor="#FF0000" data-sentry-element="stop" data-sentry-source-file="Fire.tsx" />
                <stop offset="0.2317" stopColor="#FF1500" data-sentry-element="stop" data-sentry-source-file="Fire.tsx" />
                <stop offset="0.7367" stopColor="#FF3D00" data-sentry-element="stop" data-sentry-source-file="Fire.tsx" />
                <stop offset="0.9987" stopColor="#FF4C00" data-sentry-element="stop" data-sentry-source-file="Fire.tsx" />
            </linearGradient>
            <linearGradient id="paint2_linear_5513_112654" x1="14.3129" y1="30.0783" x2="9.64226" y2="-3.76944" gradientUnits="userSpaceOnUse" data-sentry-element="linearGradient" data-sentry-source-file="Fire.tsx">
                <stop stopColor="#FF0000" data-sentry-element="stop" data-sentry-source-file="Fire.tsx" />
                <stop offset="0.2317" stopColor="#FF1500" data-sentry-element="stop" data-sentry-source-file="Fire.tsx" />
                <stop offset="0.7367" stopColor="#FF3D00" data-sentry-element="stop" data-sentry-source-file="Fire.tsx" />
                <stop offset="0.9987" stopColor="#FF4C00" data-sentry-element="stop" data-sentry-source-file="Fire.tsx" />
            </linearGradient>
            <linearGradient id="paint3_linear_5513_112654" x1="17.1583" y1="23.9027" x2="6.9521" y2="-5.24559" gradientUnits="userSpaceOnUse" data-sentry-element="linearGradient" data-sentry-source-file="Fire.tsx">
                <stop stopColor="#FF8000" data-sentry-element="stop" data-sentry-source-file="Fire.tsx" />
                <stop offset="0.6388" stopColor="#FF5B00" data-sentry-element="stop" data-sentry-source-file="Fire.tsx" />
                <stop offset="0.9987" stopColor="#FF4C00" data-sentry-element="stop" data-sentry-source-file="Fire.tsx" />
            </linearGradient>
            <radialGradient id="paint4_radial_5513_112654" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(21.5291 0.181156) scale(13.6008 13.6008)" data-sentry-element="radialGradient" data-sentry-source-file="Fire.tsx">
                <stop offset="0.00134333" stopColor="#FFED1C" stopOpacity="0.6" data-sentry-element="stop" data-sentry-source-file="Fire.tsx" />
                <stop offset="0.9477" stopColor="#FFED1C" stopOpacity="0.0264782" data-sentry-element="stop" data-sentry-source-file="Fire.tsx" />
                <stop offset="0.9914" stopColor="#FFED1C" stopOpacity="0" data-sentry-element="stop" data-sentry-source-file="Fire.tsx" />
            </radialGradient>
            <linearGradient id="paint5_linear_5513_112654" x1="13.1956" y1="4.45232" x2="16.886" y2="25.1531" gradientUnits="userSpaceOnUse" data-sentry-element="linearGradient" data-sentry-source-file="Fire.tsx">
                <stop stopColor="#FF8000" data-sentry-element="stop" data-sentry-source-file="Fire.tsx" />
                <stop offset="0.022669" stopColor="#FE7F02" stopOpacity="0.9773" data-sentry-element="stop" data-sentry-source-file="Fire.tsx" />
                <stop offset="0.6663" stopColor="#ED582A" stopOpacity="0.3337" data-sentry-element="stop" data-sentry-source-file="Fire.tsx" />
                <stop offset="1" stopColor="#E74939" stopOpacity="0" data-sentry-element="stop" data-sentry-source-file="Fire.tsx" />
            </linearGradient>
            <linearGradient id="paint6_linear_5513_112654" x1="16.0623" y1="26.8208" x2="16.8599" y2="12.2858" gradientUnits="userSpaceOnUse" data-sentry-element="linearGradient" data-sentry-source-file="Fire.tsx">
                <stop stopColor="#FFBC47" data-sentry-element="stop" data-sentry-source-file="Fire.tsx" />
                <stop offset="0.1869" stopColor="#FFB137" stopOpacity="0.8131" data-sentry-element="stop" data-sentry-source-file="Fire.tsx" />
                <stop offset="0.7223" stopColor="#FF940F" stopOpacity="0.2777" data-sentry-element="stop" data-sentry-source-file="Fire.tsx" />
                <stop offset="1" stopColor="#FF8900" stopOpacity="0" data-sentry-element="stop" data-sentry-source-file="Fire.tsx" />
            </linearGradient>
            <radialGradient id="paint7_radial_5513_112654" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(15.8626 22.8986) scale(10.521 8.54092)" data-sentry-element="radialGradient" data-sentry-source-file="Fire.tsx">
                <stop offset="0.00134333" stopColor="#FFBC47" data-sentry-element="stop" data-sentry-source-file="Fire.tsx" />
                <stop offset="0.2941" stopColor="#FFBF4B" stopOpacity="0.7068" data-sentry-element="stop" data-sentry-source-file="Fire.tsx" />
                <stop offset="0.5624" stopColor="#FEC656" stopOpacity="0.4382" data-sentry-element="stop" data-sentry-source-file="Fire.tsx" />
                <stop offset="0.8201" stopColor="#FCD368" stopOpacity="0.1801" data-sentry-element="stop" data-sentry-source-file="Fire.tsx" />
                <stop offset="1" stopColor="#FBE07A" stopOpacity="0" data-sentry-element="stop" data-sentry-source-file="Fire.tsx" />
            </radialGradient>
            <radialGradient id="paint8_radial_5513_112654" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(15.8626 22.466) scale(10.1968 8.27773)" data-sentry-element="radialGradient" data-sentry-source-file="Fire.tsx">
                <stop offset="0.00134333" stopColor="white" data-sentry-element="stop" data-sentry-source-file="Fire.tsx" />
                <stop offset="1" stopColor="white" stopOpacity="0" data-sentry-element="stop" data-sentry-source-file="Fire.tsx" />
            </radialGradient>
            <radialGradient id="paint9_radial_5513_112654" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(11.3919 24.3468) scale(12.526 14.584)" data-sentry-element="radialGradient" data-sentry-source-file="Fire.tsx">
                <stop offset="0.00134333" stopColor="#FFED1C" stopOpacity="0.6" data-sentry-element="stop" data-sentry-source-file="Fire.tsx" />
                <stop offset="0.9477" stopColor="#FFED1C" stopOpacity="0.0264782" data-sentry-element="stop" data-sentry-source-file="Fire.tsx" />
                <stop offset="0.9914" stopColor="#FFED1C" stopOpacity="0" data-sentry-element="stop" data-sentry-source-file="Fire.tsx" />
            </radialGradient>
            <radialGradient id="paint10_radial_5513_112654" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(15.8631 22.465) rotate(92.8876) scale(3.95883 3.2136)" data-sentry-element="radialGradient" data-sentry-source-file="Fire.tsx">
                <stop offset="0.00134333" stopColor="white" data-sentry-element="stop" data-sentry-source-file="Fire.tsx" />
                <stop offset="1" stopColor="white" stopOpacity="0" data-sentry-element="stop" data-sentry-source-file="Fire.tsx" />
            </radialGradient>
            <radialGradient id="paint11_radial_5513_112654" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(18.051 5.31113) scale(4.49357)" data-sentry-element="radialGradient" data-sentry-source-file="Fire.tsx">
                <stop offset="0.00134333" stopColor="#FFED1C" data-sentry-element="stop" data-sentry-source-file="Fire.tsx" />
                <stop offset="0.1997" stopColor="#FFEE2B" stopOpacity="0.8013" data-sentry-element="stop" data-sentry-source-file="Fire.tsx" />
                <stop offset="0.5839" stopColor="#FFF254" stopOpacity="0.4167" data-sentry-element="stop" data-sentry-source-file="Fire.tsx" />
                <stop offset="1" stopColor="#FFF686" stopOpacity="0" data-sentry-element="stop" data-sentry-source-file="Fire.tsx" />
            </radialGradient>
            <radialGradient id="paint12_radial_5513_112654" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(10.6421 7.47303) rotate(-56.4116) scale(3.63989 0.826633)" data-sentry-element="radialGradient" data-sentry-source-file="Fire.tsx">
                <stop offset="0.00134333" stopColor="#FFED1C" stopOpacity="0.6" data-sentry-element="stop" data-sentry-source-file="Fire.tsx" />
                <stop offset="0.9477" stopColor="#FFED1C" stopOpacity="0.0264782" data-sentry-element="stop" data-sentry-source-file="Fire.tsx" />
                <stop offset="0.9914" stopColor="#FFED1C" stopOpacity="0" data-sentry-element="stop" data-sentry-source-file="Fire.tsx" />
            </radialGradient>
            <radialGradient id="paint13_radial_5513_112654" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(11.3629 4.61876) rotate(95.0573) scale(3.27063 1.316)" data-sentry-element="radialGradient" data-sentry-source-file="Fire.tsx">
                <stop offset="0.00134333" stopColor="#FFED1C" data-sentry-element="stop" data-sentry-source-file="Fire.tsx" />
                <stop offset="0.1997" stopColor="#FFEE2B" stopOpacity="0.8013" data-sentry-element="stop" data-sentry-source-file="Fire.tsx" />
                <stop offset="0.5839" stopColor="#FFF254" stopOpacity="0.4167" data-sentry-element="stop" data-sentry-source-file="Fire.tsx" />
                <stop offset="1" stopColor="#FFF686" stopOpacity="0" data-sentry-element="stop" data-sentry-source-file="Fire.tsx" />
            </radialGradient>
            <radialGradient id="paint14_radial_5513_112654" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(17.053 4.73417) scale(14.0213 14.0213)" data-sentry-element="radialGradient" data-sentry-source-file="Fire.tsx">
                <stop offset="0.00134333" stopColor="#FF4C00" data-sentry-element="stop" data-sentry-source-file="Fire.tsx" />
                <stop offset="0.135" stopColor="#FF5B0C" stopOpacity="0.8661" data-sentry-element="stop" data-sentry-source-file="Fire.tsx" />
                <stop offset="0.3941" stopColor="#FF842C" stopOpacity="0.6067" data-sentry-element="stop" data-sentry-source-file="Fire.tsx" />
                <stop offset="0.7491" stopColor="#FFC45F" stopOpacity="0.2512" data-sentry-element="stop" data-sentry-source-file="Fire.tsx" />
                <stop offset="1" stopColor="#FFF686" stopOpacity="0" data-sentry-element="stop" data-sentry-source-file="Fire.tsx" />
            </radialGradient>
            <radialGradient id="paint15_radial_5513_112654" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(23.4302 16.0936) scale(7.44229 7.44229)" data-sentry-element="radialGradient" data-sentry-source-file="Fire.tsx">
                <stop offset="0.00134333" stopColor="#FFED1C" data-sentry-element="stop" data-sentry-source-file="Fire.tsx" />
                <stop offset="0.1997" stopColor="#FFEE2B" stopOpacity="0.8013" data-sentry-element="stop" data-sentry-source-file="Fire.tsx" />
                <stop offset="0.5839" stopColor="#FFF254" stopOpacity="0.4167" data-sentry-element="stop" data-sentry-source-file="Fire.tsx" />
                <stop offset="1" stopColor="#FFF686" stopOpacity="0" data-sentry-element="stop" data-sentry-source-file="Fire.tsx" />
            </radialGradient>
            <radialGradient id="paint16_radial_5513_112654" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(8.19568 18.7462) scale(7.93253 7.93253)" data-sentry-element="radialGradient" data-sentry-source-file="Fire.tsx">
                <stop offset="0.00134333" stopColor="#FFED1C" data-sentry-element="stop" data-sentry-source-file="Fire.tsx" />
                <stop offset="0.1997" stopColor="#FFEE2B" stopOpacity="0.8013" data-sentry-element="stop" data-sentry-source-file="Fire.tsx" />
                <stop offset="0.5839" stopColor="#FFF254" stopOpacity="0.4167" data-sentry-element="stop" data-sentry-source-file="Fire.tsx" />
                <stop offset="1" stopColor="#FFF686" stopOpacity="0" data-sentry-element="stop" data-sentry-source-file="Fire.tsx" />
            </radialGradient>
            <radialGradient id="paint17_radial_5513_112654" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(15.7442 17.1428) rotate(86.1102) scale(13.5879 8.26001)" data-sentry-element="radialGradient" data-sentry-source-file="Fire.tsx">
                <stop stopColor="#FF4C00" stopOpacity="0" data-sentry-element="stop" data-sentry-source-file="Fire.tsx" />
                <stop offset="0.9874" stopColor="#FF4C00" stopOpacity="0.9888" data-sentry-element="stop" data-sentry-source-file="Fire.tsx" />
                <stop offset="0.9987" stopColor="#FF4C00" data-sentry-element="stop" data-sentry-source-file="Fire.tsx" />
            </radialGradient>
        </defs>
    </svg>;
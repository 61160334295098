import { FC, SVGProps } from "react";
export const PoutingFace: FC<SVGProps<SVGSVGElement>> = props => <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none" {...props} data-sentry-element="svg" data-sentry-component="PoutingFace" data-sentry-source-file="PoutingFace.tsx">
        <path d="M16.0001 28.1905C22.7327 28.1905 28.1905 22.7326 28.1905 16C28.1905 9.26745 22.7327 3.80957 16.0001 3.80957C9.26745 3.80957 3.80957 9.26745 3.80957 16C3.80957 22.7326 9.26745 28.1905 16.0001 28.1905Z" fill="url(#paint0_radial_5513_112692)" data-sentry-element="path" data-sentry-source-file="PoutingFace.tsx" />
        <path d="M15.7636 20.9524C15.7636 20.9524 18.2931 20.9448 19.1617 22.9562C19.215 23.0857 19.2531 23.2228 19.2607 23.36C19.2836 23.6419 19.0855 23.9314 18.6055 23.741C17.8359 23.4362 16.5103 23.2838 15.7636 23.2838C15.0169 23.2838 13.6988 23.4362 12.9217 23.741C12.4417 23.9314 12.2436 23.6419 12.2664 23.36C12.2741 23.2228 12.3121 23.0857 12.3655 22.9562C13.2417 20.9448 15.7636 20.9524 15.7636 20.9524Z" fill="url(#paint1_radial_5513_112692)" data-sentry-element="path" data-sentry-source-file="PoutingFace.tsx" />
        <path d="M12.0229 19.0933C12.671 19.0933 13.1963 18.2985 13.1963 17.3181C13.1963 16.3377 12.671 15.5428 12.0229 15.5428C11.3749 15.5428 10.8496 16.3377 10.8496 17.3181C10.8496 18.2985 11.3749 19.0933 12.0229 19.0933Z" fill="url(#paint2_radial_5513_112692)" data-sentry-element="path" data-sentry-source-file="PoutingFace.tsx" />
        <path d="M11.5578 16.6781C12.2435 16.6781 12.815 17.3791 12.9826 18.3314C13.1197 18.0419 13.1959 17.6914 13.1959 17.3105C13.1959 16.3276 12.6702 15.5353 12.0226 15.5353C11.4588 15.5353 10.9864 16.1296 10.8721 16.9296C11.0778 16.7696 11.3064 16.6781 11.5578 16.6781Z" fill="url(#paint3_linear_5513_112692)" data-sentry-element="path" data-sentry-source-file="PoutingFace.tsx" />
        <path d="M10.7654 12.9905C9.71395 12.9905 8.81491 14.202 9.98823 14.3848C11.1616 14.5677 13.2949 15.581 14.2016 16.282C14.2701 16.3658 14.5368 16.3353 14.5139 16.1296C14.5139 15.3372 12.0454 12.9905 10.7654 12.9905Z" fill="url(#paint4_linear_5513_112692)" data-sentry-element="path" data-sentry-source-file="PoutingFace.tsx" />
        <path d="M15.7641 21.6153C15.7641 21.6153 17.8213 21.6077 19.2308 23.1925C19.2156 23.1086 19.1927 23.0325 19.1546 22.9563C18.2861 20.9448 15.7565 20.9525 15.7565 20.9525C15.7565 20.9525 13.227 20.9448 12.3584 22.9563C12.3279 23.0325 12.3051 23.1086 12.2822 23.1925C13.707 21.6077 15.7641 21.6153 15.7641 21.6153Z" fill="url(#paint5_linear_5513_112692)" data-sentry-element="path" data-sentry-source-file="PoutingFace.tsx" />
        <path d="M19.9771 19.0933C20.6251 19.0933 21.1504 18.2985 21.1504 17.3181C21.1504 16.3377 20.6251 15.5428 19.9771 15.5428C19.329 15.5428 18.8037 16.3377 18.8037 17.3181C18.8037 18.2985 19.329 19.0933 19.9771 19.0933Z" fill="url(#paint6_radial_5513_112692)" data-sentry-element="path" data-sentry-source-file="PoutingFace.tsx" />
        <path d="M20.4418 16.6781C19.7561 16.6781 19.1847 17.3791 19.0171 18.3314C18.8799 18.0419 18.8037 17.6914 18.8037 17.3105C18.8037 16.3276 19.3294 15.5353 19.9771 15.5353C20.5409 15.5353 21.0132 16.1296 21.1276 16.9296C20.9218 16.7696 20.6932 16.6781 20.4418 16.6781Z" fill="url(#paint7_linear_5513_112692)" data-sentry-element="path" data-sentry-source-file="PoutingFace.tsx" />
        <path d="M21.2343 12.9905C22.2857 12.9905 23.1848 14.2019 22.0114 14.3848C20.8381 14.5676 18.7048 15.581 17.7981 16.2819C17.7296 16.3657 17.4629 16.3352 17.4858 16.1295C17.4858 15.3372 19.9543 12.9905 21.2343 12.9905Z" fill="url(#paint8_linear_5513_112692)" data-sentry-element="path" data-sentry-source-file="PoutingFace.tsx" />
        <defs data-sentry-element="defs" data-sentry-source-file="PoutingFace.tsx">
            <radialGradient id="paint0_radial_5513_112692" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(13.2526 11.1471) rotate(-29.698) scale(12.3021 10.0501)" data-sentry-element="radialGradient" data-sentry-source-file="PoutingFace.tsx">
                <stop stopColor="#FF9214" data-sentry-element="stop" data-sentry-source-file="PoutingFace.tsx" />
                <stop offset="1" stopColor="#FF4E0D" data-sentry-element="stop" data-sentry-source-file="PoutingFace.tsx" />
            </radialGradient>
            <radialGradient id="paint1_radial_5513_112692" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(15.7649 22.3785) scale(2.6692 1.42188)" data-sentry-element="radialGradient" data-sentry-source-file="PoutingFace.tsx">
                <stop offset="0.00132565" stopColor="#7A4400" data-sentry-element="stop" data-sentry-source-file="PoutingFace.tsx" />
                <stop offset="1" stopColor="#643800" data-sentry-element="stop" data-sentry-source-file="PoutingFace.tsx" />
            </radialGradient>
            <radialGradient id="paint2_radial_5513_112692" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(11.6638 17.3584) rotate(73.8539) scale(1.72243 1.11438)" data-sentry-element="radialGradient" data-sentry-source-file="PoutingFace.tsx">
                <stop offset="0.00132565" stopColor="#7A4400" data-sentry-element="stop" data-sentry-source-file="PoutingFace.tsx" />
                <stop offset="1" stopColor="#643800" data-sentry-element="stop" data-sentry-source-file="PoutingFace.tsx" />
            </radialGradient>
            <linearGradient id="paint3_linear_5513_112692" x1="12.0335" y1="16.081" x2="12.0335" y2="17.6699" gradientUnits="userSpaceOnUse" data-sentry-element="linearGradient" data-sentry-source-file="PoutingFace.tsx">
                <stop offset="0.00132565" stopColor="#3C2200" data-sentry-element="stop" data-sentry-source-file="PoutingFace.tsx" />
                <stop offset="1" stopColor="#512D00" data-sentry-element="stop" data-sentry-source-file="PoutingFace.tsx" />
            </linearGradient>
            <linearGradient id="paint4_linear_5513_112692" x1="11.9746" y1="17.213" x2="11.9746" y2="13.53" gradientUnits="userSpaceOnUse" data-sentry-element="linearGradient" data-sentry-source-file="PoutingFace.tsx">
                <stop offset="0.00132565" stopColor="#3C2200" data-sentry-element="stop" data-sentry-source-file="PoutingFace.tsx" />
                <stop offset="1" stopColor="#643800" data-sentry-element="stop" data-sentry-source-file="PoutingFace.tsx" />
            </linearGradient>
            <linearGradient id="paint5_linear_5513_112692" x1="15.7655" y1="20.0864" x2="15.7655" y2="22.0056" gradientUnits="userSpaceOnUse" data-sentry-element="linearGradient" data-sentry-source-file="PoutingFace.tsx">
                <stop offset="0.00132565" stopColor="#3C2200" data-sentry-element="stop" data-sentry-source-file="PoutingFace.tsx" />
                <stop offset="1" stopColor="#512D00" data-sentry-element="stop" data-sentry-source-file="PoutingFace.tsx" />
            </linearGradient>
            <radialGradient id="paint6_radial_5513_112692" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(20.3698 17.3297) rotate(106.146) scale(1.72243 1.11438)" data-sentry-element="radialGradient" data-sentry-source-file="PoutingFace.tsx">
                <stop offset="0.00132565" stopColor="#7A4400" data-sentry-element="stop" data-sentry-source-file="PoutingFace.tsx" />
                <stop offset="1" stopColor="#643800" data-sentry-element="stop" data-sentry-source-file="PoutingFace.tsx" />
            </radialGradient>
            <linearGradient id="paint7_linear_5513_112692" x1="19.9661" y1="16.081" x2="19.9661" y2="17.6699" gradientUnits="userSpaceOnUse" data-sentry-element="linearGradient" data-sentry-source-file="PoutingFace.tsx">
                <stop offset="0.00132565" stopColor="#3C2200" data-sentry-element="stop" data-sentry-source-file="PoutingFace.tsx" />
                <stop offset="1" stopColor="#512D00" data-sentry-element="stop" data-sentry-source-file="PoutingFace.tsx" />
            </linearGradient>
            <linearGradient id="paint8_linear_5513_112692" x1="20.0252" y1="17.2129" x2="20.0252" y2="13.5299" gradientUnits="userSpaceOnUse" data-sentry-element="linearGradient" data-sentry-source-file="PoutingFace.tsx">
                <stop offset="0.00132565" stopColor="#3C2200" data-sentry-element="stop" data-sentry-source-file="PoutingFace.tsx" />
                <stop offset="1" stopColor="#643800" data-sentry-element="stop" data-sentry-source-file="PoutingFace.tsx" />
            </linearGradient>
        </defs>
    </svg>;
import { api, ReactQueryOptions, RouterInputs } from '@api';
import { staleTimeIndex } from '@specs/constants';
type inputType = RouterInputs['PostsManager']['getPost'];
type queryOptionsType = ReactQueryOptions['PostsManager']['getPost'];
export const useGetPost = (input: inputType, options?: queryOptionsType) => {
  return api.PostsManager.getPost.useQuery(input, {
    ...options,
    queryKey: ['PostsManager.getPost', input],
    trpc: {
      abortOnUnmount: true
    },
    staleTime: 60 * 15 * 1000 * staleTimeIndex // 15 minutes
  });
};
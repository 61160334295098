import { FC } from 'react';
import { Modal } from '@components/Modals/Modal';
import { useReportFlow } from '@hooks/useReportFlow';
import { ReportStep } from '../../../../constants/report.constants';
import { SuccessStep } from '../BlockFlow/SuccessStep';
import { BlockStep } from './BlockStep';
import { ReportReasonStep } from './ReportReason';
interface Props {
  isOpen: boolean;
  targetShortId: string;
  onClose: () => void;
  isPost?: boolean;
  postId?: number;
}
export const ReportFlow: FC<Props> = ({
  isOpen,
  targetShortId,
  onClose,
  isPost = false,
  postId
}) => {
  const {
    step,
    state,
    handleSetReason,
    handleGoToBlockStep,
    handleSetBlock,
    handleSubmit,
    handleGoToSuccessStep,
    handleCancel
  } = useReportFlow({
    targetShortId,
    onComplete: onClose,
    isOpen,
    isPost,
    postId
  });
  const renderStep = () => {
    switch (step) {
      case ReportStep.REASON:
        return <ReportReasonStep selectedReason={state.reason} onSelectReason={handleSetReason} onSubmit={handleGoToBlockStep} onCancel={handleCancel} isPost={isPost} />;
      case ReportStep.BLOCK:
        return <BlockStep isBlock={state.isBlock} onSetBlock={handleSetBlock} onSubmit={handleGoToSuccessStep} onCancel={handleCancel} isPost={isPost} />;
      case ReportStep.SUCCESS:
        return <SuccessStep onComplete={handleSubmit} authorName={targetShortId} />;
      default:
        return null;
    }
  };
  return <Modal isOpen={isOpen} onClose={onClose} data-sentry-element="Modal" data-sentry-component="ReportFlow" data-sentry-source-file="ReportFlow.tsx">
			{renderStep()}
		</Modal>;
};
import { EmojiTypes } from '@components/EmojiPicker/Emojies'
import { ReactionType } from '@data/post/PostManagerSpec'
import { post_reaction, PostReactionType } from '@prisma/client'

export type EmojiesListItem = {
    name: EmojiTypes
    key: PostReactionType
}

export const REACTIONS: EmojiesListItem[] = [
    {
        name: EmojiTypes.emoji_fire,
        key: 'FIRE',
    },
    {
        name: EmojiTypes.emoji_heart,
        key: 'HEART',
    },
    {
        name: EmojiTypes.emoji_neutral_face,
        key: 'NEUTURAL_FACE',
    },
    {
        name: EmojiTypes.emoji_pouting_face,
        key: 'POUTING_FACE',
    },
]

export class ReactionsMapper {
    static mapFeedReactions(allReactions?: ReactionType[]) {
        if (!allReactions) return []

        return allReactions.map(({ type, _count }) => ({
            key: type,
            name: REACTIONS.find((item) => item.key === type)?.name as EmojiTypes,
            count: _count._all,
        }))
    }

    static mapPersonalReactions(personalReactions?: post_reaction[]) {
        if (!personalReactions) return undefined

        const hashMap = new Map<PostReactionType, PostReactionType>()

        personalReactions.forEach((reaction) => {
            hashMap.set(reaction.type, REACTIONS.find((item) => item.key === reaction.type)?.key as PostReactionType)
        })

        return Object.fromEntries(hashMap) as Record<PostReactionType, PostReactionType>
    }

    static getPostReactions(personalReactions?: ReactionType[], allReactions?: post_reaction[]) {
        return {
            allReactions: ReactionsMapper.mapFeedReactions(personalReactions),
            personalReactions: ReactionsMapper.mapPersonalReactions(allReactions),
        }
    }
}

import { FC } from 'react';
import { ReportReason } from '@prisma/client';
import { REPORT_VARIANTS } from '@specs/reports';
import { SpriteIcon } from '@components/Sprites/SpriteIcon';
import { Button } from '@components/UIKit/Button/Button';
import { Checkbox } from '@components/UIKit/Checkbox/Checkbox';
import { Divider } from '@components/UIKit/Divider/Divider';
import { useTranslation } from '@hooks/useTranslation';
import s from './reportFlow.module.scss';
interface Props {
  selectedReason: ReportReason | null;
  onSelectReason: (reason: ReportReason) => void;
  onSubmit: () => Promise<void>;
  onCancel: () => void;
  isPost?: boolean;
}
export const ReportReasonStep: FC<Props> = ({
  selectedReason,
  onSelectReason,
  onSubmit,
  onCancel,
  isPost = false
}) => {
  const {
    translate: tr
  } = useTranslation();
  return <div className={s.container} data-sentry-component="ReportReasonStep" data-sentry-source-file="ReportReason.tsx">
			<div className={s.header}>
				<h3 className={s.header_title}>
					{tr({
          id: 'report.reason.title'
        })}
				</h3>
				<button className={s.header_close} onClick={onCancel}>
					<SpriteIcon name='close_small' width={32} height={32} style={{
          backgroundColor: '#F2F2F2',
          borderRadius: '50%',
          padding: 8
        }} data-sentry-element="SpriteIcon" data-sentry-source-file="ReportReason.tsx" />
				</button>
			</div>

			<div className={s.body}>
				<h3 className={s.body_subtitle}>
					{tr({
          id: 'report.reason.subtitle'
        }, {
          target: isPost ? tr({
            id: 'report.target.post'
          }) : tr({
            id: 'report.target.author'
          })
        })}
				</h3>

				<span className={s.body_text}>
					{tr({
          id: 'report.reason.text'
        })}
				</span>

				<div className={s.body_list}>
					{REPORT_VARIANTS.map(({
          id,
          value,
          langKey
        }) => <button key={id} className={s.body_list_item} onClick={() => onSelectReason(value)}>
							<Checkbox variant='circle' onChange={() => onSelectReason(value)} checked={selectedReason === value} />
							{tr({
            id: langKey
          })}
						</button>)}
				</div>

				<Divider data-sentry-element="Divider" data-sentry-source-file="ReportReason.tsx" />

				<div className={s.body_buttons}>
					<Button id='default_button' color='Black' size='medium' disabled={!selectedReason} onClick={onSubmit} data-sentry-element="Button" data-sentry-source-file="ReportReason.tsx">
						{tr({
            id: 'btn.report'
          })}
					</Button>
					<Button id='default_button' color='Transparent' size='medium' onClick={onCancel} className={s.body_buttons_cancel} data-sentry-element="Button" data-sentry-source-file="ReportReason.tsx">
						{tr({
            id: 'btn.cancel'
          })}
					</Button>
				</div>
			</div>
		</div>;
};
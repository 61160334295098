import React, { FC, useEffect, useRef } from 'react';
import s from './postCard.module.scss';
import { EmojiPicker } from '@components/EmojiPicker/EmodjiPicker';
import { post, post_reaction, PostReactionType } from '@prisma/client';
import { useToggleReaction } from '@services/posts/hooks/useToggleReaction';
import { useSession } from 'next-auth/react';
import { useRouter } from 'next/router';
import { LinksEnum } from '@specs/navigation';
import { ShareLink } from '@components/ShareLink/ShareLink';
import { REACTIONS } from '@services/posts/helpers/reactions';
import { useProfile } from '@hooks/TRPC_Hooks/useProfile';
import { api } from '@api';
type PostCardFooterProps = {
  postId: post['id'];
  link: string;
  postReactions: post_reaction[];
};
const channelName = 'invalidate-post';
const channelEventType = 'invalidate';
export const PostCardFooter: FC<PostCardFooterProps> = ({
  postId,
  link,
  postReactions
}) => {
  const utils = api.useUtils();
  const router = useRouter();
  const {
    status
  } = useSession();
  const channelRef = useRef<BroadcastChannel | null>(null);
  const {
    data: myProfile
  } = useProfile();
  const {
    mutateAsync: toggleReaction
  } = useToggleReaction({
    onSuccess: async data => {
      if (data.status === 'success') {
        await utils.PostsManager.getPost.invalidate({
          post_id: postId
        });
        channelRef.current?.postMessage?.({
          type: channelEventType,
          postId
        });
      }
    }
  });
  const groupReactionsByType = (postReactions: post_reaction[], myProfileId?: number) => {
    return postReactions.reduce((acc, reaction) => {
      const {
        type,
        profile_id
      } = reaction;
      if (!acc[type]) {
        acc[type] = {
          count: 0,
          personal: false
        };
      }
      acc[type].count += 1;
      if (profile_id === myProfileId) {
        acc[type].personal = true;
      }
      return acc;
    }, {} as Record<PostReactionType, {
      count: number;
      personal: boolean;
    }>);
  };
  const reactions = groupReactionsByType(postReactions, myProfile?.id);
  const handleReactionToggle = async (key: PostReactionType) => {
    if (status === 'unauthenticated') {
      await router.push(LinksEnum.SIGN_IN);
      return;
    }
    await toggleReaction({
      post_id: postId,
      type: key
    });
  };
  useEffect(() => {
    const channel = new BroadcastChannel(channelName);
    channelRef.current = channel;
    channel.onmessage = async event => {
      if (event.data.type !== channelEventType || event.data.postId !== postId) return;
      await utils.PostsManager.getPost.invalidate({
        post_id: postId
      });
    };
    return () => {
      channel.close();
    };
  }, []);
  return <div className={s.postCard__footer} data-sentry-component="PostCardFooter" data-sentry-source-file="PostCardFooter.tsx">
            <EmojiPicker reactions={reactions} onEmojiSelect={handleReactionToggle} emojiList={REACTIONS} data-sentry-element="EmojiPicker" data-sentry-source-file="PostCardFooter.tsx" />

            <div className={s.postCard__footer__right}>
                {/*<div className={s.postCard__footer__viewsAmount}>*/}
                {/*    <SpriteIcon name='eye' />*/}
                {/*    {nFormatter(viewAmount, 1)}*/}
                {/*</div>*/}
                <ShareLink link={link} className="_desktop" data-sentry-element="ShareLink" data-sentry-source-file="PostCardFooter.tsx" />
            </div>
        </div>;
};
import { Button } from '@components/UIKit/Button/Button';
import { Divider } from '@components/UIKit/Divider/Divider';
import { useTranslation } from '@hooks/useTranslation';
import s from './blockProfile.module.scss';
import { FC, useCallback } from 'react';
import { SpriteIcon } from '@components/Sprites/SpriteIcon';
import { useShowToast } from '@hooks/useShowToast';
interface Props {
  onCancel: () => void;
  onSubmit: () => void;
}
export const BlockStep: FC<Props> = ({
  onCancel,
  onSubmit
}) => {
  const {
    translate: tr
  } = useTranslation();
  const {
    showToast
  } = useShowToast();
  const handleBlock = useCallback(async () => {
    try {
      await onSubmit();
    } catch (error) {
      showToast({
        variant: 'error',
        text: 'error.default'
      });
    }
  }, [onSubmit, showToast]);
  return <div className={s.container} data-sentry-component="BlockStep" data-sentry-source-file="BlockStep.tsx">
			<div className={s.container__header}>
				<h3 className={s.container__header_title}>
					{tr({
          id: 'block.modal.title'
        })}
				</h3>

				<button onClick={onCancel} className={s.container__header_close}>
					<SpriteIcon name='close_small' width={16} height={16} data-sentry-element="SpriteIcon" data-sentry-source-file="BlockStep.tsx" />
				</button>
			</div>

			<p className={s.container__text}>
				{tr({
        id: 'block.modal.description'
      })}
			</p>

			<Divider data-sentry-element="Divider" data-sentry-source-file="BlockStep.tsx" />

			<div className={s.container__buttons}>
				<Button id='default_button' color='Black' size='large' onClick={handleBlock} data-sentry-element="Button" data-sentry-source-file="BlockStep.tsx">
					{tr({
          id: 'btn.block'
        })}
				</Button>

				<Button id='default_button' className={s.container__buttons_cancel} color='Transparent' size='large' onClick={onCancel} key='cancel' data-sentry-element="Button" data-sentry-source-file="BlockStep.tsx">
					{tr({
          id: 'btn.cancel'
        })}
				</Button>
			</div>
		</div>;
};
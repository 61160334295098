import React, { FC, useMemo, useRef, useState } from 'react';
import s from './emojiPicker.module.scss';
import cn from 'classnames';
import { PostReactionType } from '@prisma/client';
import { Button } from '../UIKit/Button/Button';
import { SpriteIcon } from '../Sprites/SpriteIcon';
import { EmojiIcon, EmojiTypes } from './Emojies';
import { useOnClickOutside } from '@hooks/useClickOutside';
import { nFormatter } from '@helpers/helpers';
import { EmojiIconTypeDictionary } from './Emojies';
type ReactionPayload = {
  count: number;
  personal: boolean;
};
type EmojiReactionsProps = {
  onEmojiSelect: (emojies: PostReactionType) => Promise<void>;
  reactions: Record<PostReactionType, ReactionPayload>;
  emojiList: {
    name: EmojiTypes;
    key: PostReactionType;
  }[];
};
export const EmojiPicker: FC<EmojiReactionsProps> = ({
  onEmojiSelect,
  emojiList,
  reactions
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const emojiRef = useRef<HTMLDivElement>(null);
  useOnClickOutside(emojiRef, () => {
    setIsOpen(false);
  });
  const handleToggleOpen = () => {
    setIsOpen(!isOpen);
  };
  const handleLike = () => {
    onEmojiSelect('HEART');
    setIsOpen(false);
  };
  const handleEmoji = async (emoji: PostReactionType) => {
    await onEmojiSelect(emoji);
    setIsOpen(false);
  };
  const noReactions = Object.entries(reactions).length === 0;
  const maxReactions = Object.entries(reactions).length >= 4;
  const mappedReactions = useMemo(() => (Object.entries(reactions) as [PostReactionType, ReactionPayload][]).sort(([emojiA], [emojiB]) => {
    if (emojiA === 'HEART') return -1;
    if (emojiB === 'HEART') return 1;
    return 0;
  }), [reactions]);
  return <div className={s.reactionsWrapper} data-sentry-component="EmojiPicker" data-sentry-source-file="EmodjiPicker.tsx">

            {noReactions ? <Button id='default_button' size='extra_small' color={'Zhen Zhu Bai Pearl'} onClick={handleLike} icons={{
      left: {
        name: 'heart'
      }
    }} /> : mappedReactions.map(([emoji, obj]) => <div className={s.selectedEmojis} key={emoji}>
                                    <Button id='default_button' key={emoji} size='extra_small' color={obj.personal ? 'White' : 'Zhen Zhu Bai Pearl'} onClick={async () => {
        await onEmojiSelect(emoji as PostReactionType);
      }} className={cn(s.selectedEmojis__item, emoji === 'HEART' && s.selectedEmojis__item_heart)}>
                                        <div className={s.emojiIcon}>
                                            {EmojiIcon[EmojiIconTypeDictionary[emoji]]({
            width: 20,
            height: 20
          })}
                                            <span>{nFormatter(obj.count, 1)}</span>
                                        </div>
                                    </Button>
                                </div>)}

            {<div className={s.menuButtonWrapper} ref={emojiRef}>
                    {!maxReactions && <Button id='default_button' size='extra_small' color='Zhen Zhu Bai Pearl' onClick={handleToggleOpen}>
                        <SpriteIcon name='plus' />
                    </Button>}
                    <div className={cn(s.emojiMenu, {
        [s.emojiMenu__open]: isOpen
      })}>
                        {emojiList.map(emoji => <Button id='default_button' key={emoji.name} size='extra_small' color='Zhen Zhu Bai Pearl' onClick={() => handleEmoji(emoji.key)}>
                                {EmojiIcon[emoji.name]({
            width: 20,
            height: 20
          })}
                            </Button>)}
                    </div>
                </div>}
        </div>;
};
import { useSession } from 'next-auth/react'
import { useCallback, useEffect, useState } from 'react'

import { ReportReason } from '@prisma/client'

import { ReportStep } from '../constants/report.constants'
import { useReports } from './TRPC_Hooks/useReports'
import { useShowToast } from './useShowToast'

interface ReportState {
    reason: ReportReason | null
    isBlock: boolean | null
}

interface UseReportFlowProps {
    targetShortId: string
    onComplete?: () => void
    isOpen?: boolean
    isPost?: boolean
    postId?: number
}

export const useReportFlow = ({ targetShortId, onComplete, isOpen, isPost = false, postId }: UseReportFlowProps) => {
    const { status } = useSession()
    const { showToast } = useShowToast()

    const [step, setStep] = useState<ReportStep>(ReportStep.INITIAL)
    const [state, setState] = useState<ReportState>({
        reason: null,
        isBlock: null,
    })

    const { createReport } = useReports()

    useEffect(() => {
        if (isOpen) {
            setStep(ReportStep.REASON)
        } else {
            setState({ reason: null, isBlock: null })
            setStep(ReportStep.INITIAL)
        }
    }, [isOpen])

    const handleSetReason = useCallback((reason: ReportReason) => {
        setState((prev) => ({ ...prev, reason }))
    }, [])

    const handleGoToSuccessStep: () => Promise<void> = useCallback(async () => {
        if (state.isBlock) {
            setStep(ReportStep.SUCCESS)
        } else {
            await handleSubmit()
        }
    }, [state])

    const handleSetBlock = useCallback((isBlock: boolean) => {
        setState((prev) => ({ ...prev, isBlock }))
    }, [])

    const closeFlow = useCallback(() => {
        setState({
            reason: null,
            isBlock: null,
        })
        onComplete?.()
    }, [onComplete])

    const handleSubmit = useCallback(async () => {
        try {
            if (!state.reason) return

            const reportBody = {
                targetShortId,
                reason: state.reason,
                isBlock: state.isBlock ?? false,
                postId: isPost ? postId : undefined,
            }

            if (status !== 'authenticated') {
                const postReports = JSON.parse(localStorage.getItem(`postReports`) || '[]')
                const newReports = new Set([...postReports, reportBody.postId])

                localStorage.setItem(`postReports`, JSON.stringify([...newReports]))

                // Dispatch custom event
                window.dispatchEvent(new Event('storage'))

                showToast({
                    variant: 'success',
                    text: 'successful',
                })
            } else {
                await createReport(reportBody)
            }

            closeFlow()
        } catch (error) {
            console.error('Report flow error:', error)
            showToast({
                variant: 'error',
                text: 'error.default',
            })
        }
    }, [state, targetShortId, createReport, onComplete, isPost, status])

    const handleGoToBlockStep = useCallback(async () => {
        if (status !== 'authenticated') {
            handleSubmit()
        } else {
            setStep(ReportStep.BLOCK)
        }
    }, [status, handleSubmit])

    const handleCancel = useCallback(() => {
        setState({ reason: null, isBlock: null })
        onComplete?.()
        setStep(ReportStep.INITIAL)
    }, [onComplete])

    return {
        step,
        state,
        handleSetReason,
        handleGoToBlockStep,
        handleSetBlock,
        handleSubmit,
        handleCancel,
        handleGoToSuccessStep,
    }
}

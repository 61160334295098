import { api, ReactQueryOptions } from '@api';
import { useProfile } from '@hooks/TRPC_Hooks/useProfile';
import { useShowToast } from '@hooks/useShowToast';
import { PostView } from '@data/post/PostManagerSpec';
type OptionsType = ReactQueryOptions['PostsManager']['toggleReaction'];
type ContextType = {
  prevData: PostView | null | undefined;
  post_id: number;
};
export const useToggleReaction = (options?: OptionsType) => {
  const {
    showToast
  } = useShowToast();
  const utils = api.useUtils();
  const {
    data: profile
  } = useProfile();
  return api.PostsManager.toggleReaction.useMutation({
    ...options,
    mutationKey: ['PostsManager.toggleReaction'],
    onError: (_, __, context: ContextType | undefined) => {
      const post_id = context?.post_id;
      const prevData = context?.prevData;
      if (prevData && post_id) {
        utils.PostsManager.getPost.setData({
          post_id
        }, context.prevData);
      }
      showToast({
        variant: 'error',
        text: 'error.default'
      });
    },
    onMutate: async opts => {
      await utils.PostsManager.getPost.cancel({
        post_id: opts.post_id
      });
      const prevData = utils.PostsManager.getPost.getData({
        post_id: opts.post_id
      });
      if (!prevData || !profile) return {
        prevData,
        post_id: opts.post_id
      };
      const existingReaction = prevData.post_reactions.find(reaction => reaction.type === opts.type && reaction.profile_id === profile.id);
      const profile_id = profile.id;
      const filteredReactions = prevData.post_reactions.filter(reaction => !(reaction.type === opts.type && reaction.profile_id === profile_id));
      utils.PostsManager.getPost.setData({
        post_id: opts.post_id
      }, {
        ...prevData,
        post_reactions: existingReaction ? filteredReactions : [...prevData.post_reactions, {
          type: opts.type,
          profile_id: profile_id,
          post_id: opts.post_id,
          id: -1,
          created_at: new Date()
        }]
      });
      return {
        prevData,
        post_id: opts.post_id
      };
    },
    onSuccess: (data, variables, context) => {
      options?.onSuccess?.(data, variables, context);
    }
  });
};
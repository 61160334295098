import { FC, SVGProps } from "react";
export const Heart: FC<SVGProps<SVGSVGElement>> = props => <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none" {...props} data-sentry-element="svg" data-sentry-component="Heart" data-sentry-source-file="Heart.tsx">
        <path d="M21.5087 4.95245C19.2229 4.95245 17.2039 6.10292 16 7.85531C14.7963 6.10292 12.7772 4.95245 10.4915 4.95245C6.80385 4.95245 3.80957 7.94673 3.80957 11.6343C3.80957 19.7792 16 27.101 16 27.101C16 27.101 28.1905 19.7792 28.1905 11.6343C28.1905 7.94673 25.1963 4.95245 21.5087 4.95245Z" fill="url(#paint0_radial_5513_112673)" data-sentry-element="path" data-sentry-source-file="Heart.tsx" />
        <path opacity="0.5" d="M24.2738 5.54688C27.931 11.3754 24.5634 19.0249 20.5177 21.1964C16.0529 23.5964 12.9976 22.4688 6.67383 18.8803C10.2776 23.6573 15.9995 27.0936 15.9995 27.0936C15.9995 27.0936 28.1901 19.7716 28.1901 11.6269C28.1901 8.92973 26.5823 6.5983 24.2738 5.54688Z" fill="url(#paint1_radial_5513_112673)" data-sentry-element="path" data-sentry-source-file="Heart.tsx" />
        <path opacity="0.5" d="M21.5087 4.95245C19.2229 4.95245 17.2039 6.10292 16 7.85531C14.7963 6.10292 12.7772 4.95245 10.4915 4.95245C6.80385 4.95245 3.80957 7.94673 3.80957 11.6343C3.80957 19.7792 16 27.101 16 27.101C16 27.101 28.1905 19.7792 28.1905 11.6343C28.1905 7.94673 25.1963 4.95245 21.5087 4.95245Z" fill="url(#paint2_radial_5513_112673)" data-sentry-element="path" data-sentry-source-file="Heart.tsx" />
        <path opacity="0.5" d="M21.5087 4.95245C19.2229 4.95245 17.2039 6.10292 16 7.85531C14.7963 6.10292 12.7772 4.95245 10.4915 4.95245C6.80385 4.95245 3.80957 7.94673 3.80957 11.6343C3.80957 19.7792 16 27.101 16 27.101C16 27.101 28.1905 19.7792 28.1905 11.6343C28.1905 7.94673 25.1963 4.95245 21.5087 4.95245Z" fill="url(#paint3_radial_5513_112673)" data-sentry-element="path" data-sentry-source-file="Heart.tsx" />
        <path opacity="0.24" d="M14.3321 7.66509C15.0025 9.24223 13.5168 11.3832 11.0102 12.4422C8.5035 13.5013 5.93589 13.0898 5.26541 11.5203C4.59493 9.95081 6.08065 7.80223 8.58732 6.74318C11.094 5.68414 13.6616 6.08794 14.3321 7.66509Z" fill="url(#paint4_radial_5513_112673)" data-sentry-element="path" data-sentry-source-file="Heart.tsx" />
        <path opacity="0.24" d="M22.4992 6.38515C23.4516 7.43658 22.865 9.51658 21.1964 11.0252C19.5278 12.5337 17.402 12.9071 16.4498 11.8556C15.4974 10.8042 16.084 8.72419 17.7526 7.21563C19.4211 5.70706 21.5468 5.33372 22.4992 6.38515Z" fill="url(#paint5_radial_5513_112673)" data-sentry-element="path" data-sentry-source-file="Heart.tsx" />
        <path opacity="0.32" d="M21.6305 6.72768C24.9829 7.87055 28.1829 12.8229 22.7735 18.9944C19.5353 22.6896 16.0001 24.5562 11.5049 23.8248C13.9353 25.8514 16.0077 27.101 16.0077 27.101C16.0077 27.101 28.1983 19.7792 28.1983 11.6343C28.1905 7.94673 25.1963 4.95245 21.5087 4.95245C19.2229 4.95245 17.2039 6.10292 16.0001 7.85531C16.0001 7.85531 19.1772 5.88959 21.6305 6.72768Z" fill="url(#paint6_linear_5513_112673)" data-sentry-element="path" data-sentry-source-file="Heart.tsx" />
        <defs data-sentry-element="defs" data-sentry-source-file="Heart.tsx">
            <radialGradient id="paint0_radial_5513_112673" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(12.5131 11.1302) rotate(-29.408) scale(19.0752 15.1315)" data-sentry-element="radialGradient" data-sentry-source-file="Heart.tsx">
                <stop offset="0.2479" stopColor="#FF0000" data-sentry-element="stop" data-sentry-source-file="Heart.tsx" />
                <stop offset="0.8639" stopColor="#C20000" data-sentry-element="stop" data-sentry-source-file="Heart.tsx" />
            </radialGradient>
            <radialGradient id="paint1_radial_5513_112673" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(12.9843 9.96024) rotate(-29.408) scale(16.4231 13.0277)" data-sentry-element="radialGradient" data-sentry-source-file="Heart.tsx">
                <stop offset="0.2479" stopColor="#FF0000" data-sentry-element="stop" data-sentry-source-file="Heart.tsx" />
                <stop offset="1" stopColor="#C20000" data-sentry-element="stop" data-sentry-source-file="Heart.tsx" />
            </radialGradient>
            <radialGradient id="paint2_radial_5513_112673" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(12.5131 11.1302) rotate(-29.408) scale(19.0752 15.1315)" data-sentry-element="radialGradient" data-sentry-source-file="Heart.tsx">
                <stop stopColor="white" stopOpacity="0.25" data-sentry-element="stop" data-sentry-source-file="Heart.tsx" />
                <stop offset="1" stopColor="white" stopOpacity="0" data-sentry-element="stop" data-sentry-source-file="Heart.tsx" />
            </radialGradient>
            <radialGradient id="paint3_radial_5513_112673" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(19.3703 17.6058) rotate(-26.296) scale(13.9241 6.88051)" data-sentry-element="radialGradient" data-sentry-source-file="Heart.tsx">
                <stop stopColor="#BD2719" stopOpacity="0.25" data-sentry-element="stop" data-sentry-source-file="Heart.tsx" />
                <stop offset="1" stopColor="#BD2719" stopOpacity="0" data-sentry-element="stop" data-sentry-source-file="Heart.tsx" />
            </radialGradient>
            <radialGradient id="paint4_radial_5513_112673" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(9.79726 9.59269) rotate(-21.6908) scale(4.95003 3.07488)" data-sentry-element="radialGradient" data-sentry-source-file="Heart.tsx">
                <stop stopColor="white" data-sentry-element="stop" data-sentry-source-file="Heart.tsx" />
                <stop offset="1" stopColor="white" stopOpacity="0" data-sentry-element="stop" data-sentry-source-file="Heart.tsx" />
            </radialGradient>
            <radialGradient id="paint5_radial_5513_112673" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(19.4675 9.12827) rotate(-40.7634) scale(4.09835 2.546)" data-sentry-element="radialGradient" data-sentry-source-file="Heart.tsx">
                <stop stopColor="white" data-sentry-element="stop" data-sentry-source-file="Heart.tsx" />
                <stop offset="1" stopColor="white" stopOpacity="0" data-sentry-element="stop" data-sentry-source-file="Heart.tsx" />
            </radialGradient>
            <linearGradient id="paint6_linear_5513_112673" x1="18.5157" y1="35.7997" x2="20.8777" y2="3.95208" gradientUnits="userSpaceOnUse" data-sentry-element="linearGradient" data-sentry-source-file="Heart.tsx">
                <stop stopColor="#860805" data-sentry-element="stop" data-sentry-source-file="Heart.tsx" />
                <stop offset="1" stopColor="#BD2719" stopOpacity="0" data-sentry-element="stop" data-sentry-source-file="Heart.tsx" />
            </linearGradient>
        </defs>
    </svg>;
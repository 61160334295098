import { api } from '@api'

export const useInvalidate = () => {
    const utils = api.useUtils()

    const updateProfileData = async () => {
        const profile = await utils.UserManager.getProfile.fetch()
        utils.UserManager.getProfile.invalidate()
        utils.UserManager.getGoldHistory.invalidate()
        utils.UserManager.getSocials.invalidate()
        profile && utils.SocialManager.getPublicProfile.invalidate({ shortId: profile.shortid })
        profile && utils.SocialManager.countOwnerships.invalidate({ shortid: profile.shortid })
        utils.SubscriptionManager.isActive.invalidate()
        utils.UserManager.getProfileWithKyc.invalidate()
        utils.SubscriptionManager.getActiveSubscription.invalidate()
    }

    const updateBlockedUsersData = ({ targetShortId }: { targetShortId: string }) => {
        utils.BlockManager.isBlocked.invalidate({ targetShortId })
    }

    const updateFigurineData = ({ toyId }: { toyId: number }) => {
        updateProfileData()
        utils.ToyManager.getSingle.invalidate({ id: toyId })
        utils.UserManager.getCollectedToyInstances.invalidate({ toyId })
        utils.UserManager.isInWishlist.invalidate({ id: toyId })
        utils.StoreManager.getToyHistory.invalidate()
        utils.ArtistManager.getList.invalidate()
        utils.ToyManager.getList.invalidate()
        utils.ToyManager._getList.invalidate()
        utils.ToyManager.isCollectionBonusToyCollected.invalidate({ toyId })
        utils.DropManager.getCollectedToday.invalidate()
        utils.DropManager.getCollectedToday.invalidate()
        utils.PartnerDropManager.getPersonalLines.invalidate()
        utils.UserManager.getGoldHistory.invalidate()
    }

    const updatePostsData = () => {
        utils.PostsManager.getPersonalFeed.invalidate()
    }

    const updateQrCodes = () => {
        utils.ArtistManager.getAllQrCodesGroups.invalidate()
        utils.ArtistManager.countQrCodes.invalidate()
        utils.DropManager.getGroupsOfSecretCodes.invalidate()
    }

    const updateBlindBoxData = ({ toyId }: { toyId }) => {
        updateProfileData()
        updateFigurineData({ toyId })
        utils.BlindBoxManager.collectedToys.invalidate()
        utils.BlindBoxManager.ownedBlindBoxes.invalidate()
    }

    const updateRetroDropData = () => {
        updateProfileData()
        utils.DropManager.getRetroDropInfo.invalidate()
    }

    const onMint = () => {
        updateProfileData()
        utils.MintManager.getNft.invalidate()
    }

    const updateSpaceCollectionsData = ({ collections }: { collections: number[] }) => {
        collections.map((collectionId) => {
            utils.ToyManager.getCollectionEarningsCount.invalidate({ collectionId })
            utils.ToyManager.getCollectionEarningsCount.invalidate({ collectionId, includeBonus: true })
        })
    }

    const updateStoreItemsData = async ({ toyId }: { toyId: number }) => {
        const toy = await utils.ToyManager.getSingle.fetch({ id: toyId })
        onMint()
        updateFigurineData({ toyId })
        toy && updateSpaceCollectionsData({ collections: [...toy.collections.map(({ collectionid }) => collectionid), ...toy.asCollectionBonus.map(({ collectionid }) => collectionid)] })
        utils.StoreManager.getItems.invalidate({ toyId })
        utils.StoreManager.getItems.invalidate()
        utils.StoreManager.countStoreItems.invalidate()
        utils.StoreManager.getUserStoreItems.invalidate()
        utils.StoreManager.getList.invalidate()
        utils.ToyManager.getInstances.invalidate()
    }

    const updateFollowData = ({ shortId }: { shortId?: string }) => {
        utils.SocialManager.getList.invalidate()
        utils.SocialManager.countFollowers.invalidate({ shortid: shortId })
        utils.SocialManager.countFollowing.invalidate({ shortid: shortId })
        utils.SocialManager.isFollowing.invalidate({ shortid: shortId })
    }
    const updateRacksData = ({ shortId, rackId }: { shortId: string; rackId: number }) => {
        utils.SocialManager.getRacks.invalidate({ shortid: shortId })
        utils.SocialManager.getRack.invalidate({ id: rackId })
    }
    const onBurnToy = ({ toyId }: { toyId: number }) => {
        updateProfileData()
        updateFigurineData({ toyId })
        utils.StoreManager.getToyHistory.invalidate()
        utils.ToyManager.getSingle.invalidate()
    }
    const onUpdateOffer = () => {
        updateProfileData()
        utils.OfferManager.getList.invalidate()
        utils.OfferManager.getFrozen.invalidate()
        utils.OfferManager.count.invalidate()
    }

    const updateToyDraft = () => {
        utils.ToyManager.getDrafts.invalidate()
        utils.ToyManager.getDraft.invalidate()
    }

    const updateCollectionDraft = (id: number | undefined) => {
        utils.CollectionManager.getDraftsList.invalidate()
        utils.CollectionManager.countArtistCollectionsDrafts.invalidate()
        typeof id !== 'undefined' && utils.CollectionManager.getSingleDraft.invalidate({ id })
    }

    return {
        updateProfileData,
        updateBlindBoxData,
        updateSpaceCollectionsData,
        updateFigurineData,
        updateStoreItemsData,
        updateFollowData,
        updateRacksData,
        onBurnToy,
        updateRetroDropData,
        onUpdateOffer,
        onMint,
        updateToyDraft,
        updateQrCodes,
        updateCollectionDraft,
        updateBlockedUsersData,
        updatePostsData,
    }
}

export enum ReportReason {
    DISLIKE = 'DISLIKE',
    BULLYING = 'BULLYING',
    SELF_HARM = 'SELF_HARM',
    VIOLENCE = 'VIOLENCE',
    RESTRICTED_ITEMS = 'RESTRICTED_ITEMS',
    ADULT_CONTENT = 'ADULT_CONTENT',
    SCAM = 'SCAM',
    FALSE_INFORMATION = 'FALSE_INFORMATION',
}

export const REPORT_VARIANTS = [
    {
        id: 1,
        title: 'I just don’t like it',
        value: ReportReason.DISLIKE,
        langKey: 'report.reason.dislike',
    },
    {
        id: 2,
        title: 'Bullying or unwanted contact',
        value: ReportReason.BULLYING,
        langKey: 'report.reason.bullying',
    },
    {
        id: 3,
        title: 'Suicide, self-injury or eating disorders',
        value: ReportReason.SELF_HARM,
        langKey: 'report.reason.selfHarm',
    },
    {
        id: 4,
        title: 'Violence, hate or explotioation',
        value: ReportReason.VIOLENCE,
        langKey: 'report.reason.violence',
    },
    {
        id: 5,
        title: 'Selling or promoting restricted items',
        value: ReportReason.RESTRICTED_ITEMS,
        langKey: 'report.reason.restrictedItems',
    },
    {
        id: 6,
        title: 'Nudity or sexual activity',
        value: ReportReason.ADULT_CONTENT,
        langKey: 'report.reason.adultContent',
    },
    {
        id: 7,
        title: 'Scam, fraud of spam',
        value: ReportReason.SCAM,
        langKey: 'report.reason.scam',
    },
    {
        id: 8,
        title: 'False information',
        value: ReportReason.FALSE_INFORMATION,
        langKey: 'report.reason.falseInformation',
    },
] as const

export const BLOCK_VARIANTS = [
    {
        id: 1,
        title: 'Yes, block this author, please',
        value: true,
        langKey: 'report.block.yes',
    },
    {
        id: 2,
        title: 'I don’t want to block this author',
        value: false,
        langKey: 'report.block.no',
    },
] as const

export enum ReportStatus {
    PENDING = 'PENDING',
    REVIEWED = 'REVIEWED',
    RESOLVED = 'RESOLVED',
    DISMISSED = 'DISMISSED',
}

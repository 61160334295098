import { FC, SVGProps } from "react";
export const NeutralFace: FC<SVGProps<SVGSVGElement>> = props => <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none" {...props} data-sentry-element="svg" data-sentry-component="NeutralFace" data-sentry-source-file="NeutralFace.tsx">
        <path d="M16.0001 28.1905C22.7327 28.1905 28.1905 22.7326 28.1905 16C28.1905 9.26745 22.7327 3.80957 16.0001 3.80957C9.26745 3.80957 3.80957 9.26745 3.80957 16C3.80957 22.7326 9.26745 28.1905 16.0001 28.1905Z" fill="url(#paint0_radial_5513_112681)" data-sentry-element="path" data-sentry-source-file="NeutralFace.tsx" />
        <path opacity="0.5" d="M16.0001 28.1905C22.7327 28.1905 28.1905 22.7326 28.1905 16C28.1905 9.26745 22.7327 3.80957 16.0001 3.80957C9.26745 3.80957 3.80957 9.26745 3.80957 16C3.80957 22.7326 9.26745 28.1905 16.0001 28.1905Z" fill="url(#paint1_radial_5513_112681)" data-sentry-element="path" data-sentry-source-file="NeutralFace.tsx" />
        <path d="M13.7977 10.6667C13.8739 10.0877 13.3939 9.65342 12.4949 9.5391C11.7254 9.44006 9.98061 9.72958 8.59393 11.3677C8.33489 11.6725 8.63965 11.8934 8.88345 11.7105C9.69109 11.0782 11.7177 10.3925 13.1806 10.781C13.7673 10.9258 13.7977 10.6667 13.7977 10.6667Z" fill="url(#paint2_linear_5513_112681)" data-sentry-element="path" data-sentry-source-file="NeutralFace.tsx" />
        <path d="M12.023 16.5181C12.671 16.5181 13.1963 15.7233 13.1963 14.7428C13.1963 13.7624 12.671 12.9676 12.023 12.9676C11.3749 12.9676 10.8496 13.7624 10.8496 14.7428C10.8496 15.7233 11.3749 16.5181 12.023 16.5181Z" fill="url(#paint3_radial_5513_112681)" data-sentry-element="path" data-sentry-source-file="NeutralFace.tsx" />
        <path d="M12.023 13.5694C12.5258 13.5694 12.9677 14.0113 13.1963 14.6589C13.181 13.6989 12.663 12.9294 12.023 12.9294C11.383 12.9294 10.8648 13.6989 10.8496 14.6589C11.0782 14.0113 11.5125 13.5694 12.023 13.5694Z" fill="url(#paint4_linear_5513_112681)" data-sentry-element="path" data-sentry-source-file="NeutralFace.tsx" />
        <path d="M19.9771 16.518C20.6251 16.518 21.1504 15.7232 21.1504 14.7428C21.1504 13.7623 20.6251 12.9675 19.9771 12.9675C19.329 12.9675 18.8037 13.7623 18.8037 14.7428C18.8037 15.7232 19.329 16.518 19.9771 16.518Z" fill="url(#paint5_radial_5513_112681)" data-sentry-element="path" data-sentry-source-file="NeutralFace.tsx" />
        <path d="M19.9771 13.6153C19.4742 13.6153 19.0323 14.0572 18.8037 14.7049C18.819 13.7449 19.3371 12.9753 19.9771 12.9753C20.6171 12.9753 21.1352 13.7449 21.1504 14.7049C20.9218 14.0572 20.4876 13.6153 19.9771 13.6153Z" fill="url(#paint6_linear_5513_112681)" data-sentry-element="path" data-sentry-source-file="NeutralFace.tsx" />
        <path d="M18.2012 10.6667C18.1251 10.0877 18.6051 9.65342 19.5041 9.5391C20.2736 9.44006 22.0184 9.72958 23.4051 11.3677C23.6641 11.6725 23.3593 11.8934 23.1155 11.7105C22.3079 11.0782 20.2813 10.3925 18.8184 10.781C18.2317 10.9258 18.2012 10.6667 18.2012 10.6667Z" fill="url(#paint7_linear_5513_112681)" data-sentry-element="path" data-sentry-source-file="NeutralFace.tsx" />
        <path d="M21.1349 21.3181H10.8644C10.552 21.3181 10.293 21.0591 10.293 20.7467C10.293 20.4343 10.552 20.1753 10.8644 20.1753H21.1349C21.4473 20.1753 21.7063 20.4343 21.7063 20.7467C21.7063 21.0591 21.4473 21.3181 21.1349 21.3181Z" fill="url(#paint8_radial_5513_112681)" data-sentry-element="path" data-sentry-source-file="NeutralFace.tsx" />
        <path d="M10.8111 20.4801H21.1958C21.4168 20.4801 21.6149 20.6096 21.7063 20.7924C21.7063 20.7772 21.7063 20.7619 21.7063 20.7467C21.7063 20.4343 21.4473 20.1753 21.1349 20.1753H10.8644C10.552 20.1753 10.293 20.4343 10.293 20.7467C10.293 20.7619 10.293 20.7772 10.293 20.7924C10.392 20.6019 10.5825 20.4801 10.8111 20.4801Z" fill="url(#paint9_linear_5513_112681)" data-sentry-element="path" data-sentry-source-file="NeutralFace.tsx" />
        <defs data-sentry-element="defs" data-sentry-source-file="NeutralFace.tsx">
            <radialGradient id="paint0_radial_5513_112681" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(13.6015 11.0596) scale(14.7062)" data-sentry-element="radialGradient" data-sentry-source-file="NeutralFace.tsx">
                <stop stopColor="#FFE030" data-sentry-element="stop" data-sentry-source-file="NeutralFace.tsx" />
                <stop offset="1" stopColor="#FFB92E" data-sentry-element="stop" data-sentry-source-file="NeutralFace.tsx" />
            </radialGradient>
            <radialGradient id="paint1_radial_5513_112681" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(13.6015 11.0596) scale(11.57)" data-sentry-element="radialGradient" data-sentry-source-file="NeutralFace.tsx">
                <stop stopColor="#FFEA5F" data-sentry-element="stop" data-sentry-source-file="NeutralFace.tsx" />
                <stop offset="1" stopColor="#FFBC47" stopOpacity="0" data-sentry-element="stop" data-sentry-source-file="NeutralFace.tsx" />
            </radialGradient>
            <linearGradient id="paint2_linear_5513_112681" x1="11.125" y1="11.3124" x2="11.3225" y2="9.78406" gradientUnits="userSpaceOnUse" data-sentry-element="linearGradient" data-sentry-source-file="NeutralFace.tsx">
                <stop offset="0.00132565" stopColor="#3C2200" data-sentry-element="stop" data-sentry-source-file="NeutralFace.tsx" />
                <stop offset="1" stopColor="#7A4400" data-sentry-element="stop" data-sentry-source-file="NeutralFace.tsx" />
            </linearGradient>
            <radialGradient id="paint3_radial_5513_112681" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(11.664 14.7896) rotate(73.8539) scale(1.72242 1.11438)" data-sentry-element="radialGradient" data-sentry-source-file="NeutralFace.tsx">
                <stop offset="0.00132565" stopColor="#7A4400" data-sentry-element="stop" data-sentry-source-file="NeutralFace.tsx" />
                <stop offset="1" stopColor="#643800" data-sentry-element="stop" data-sentry-source-file="NeutralFace.tsx" />
            </radialGradient>
            <linearGradient id="paint4_linear_5513_112681" x1="12.0201" y1="12.9606" x2="12.0201" y2="14.6151" gradientUnits="userSpaceOnUse" data-sentry-element="linearGradient" data-sentry-source-file="NeutralFace.tsx">
                <stop offset="0.00132565" stopColor="#3C2200" data-sentry-element="stop" data-sentry-source-file="NeutralFace.tsx" />
                <stop offset="1" stopColor="#512D00" data-sentry-element="stop" data-sentry-source-file="NeutralFace.tsx" />
            </linearGradient>
            <radialGradient id="paint5_radial_5513_112681" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(19.6242 14.7898) rotate(73.8539) scale(1.72243 1.11438)" data-sentry-element="radialGradient" data-sentry-source-file="NeutralFace.tsx">
                <stop offset="0.00132565" stopColor="#7A4400" data-sentry-element="stop" data-sentry-source-file="NeutralFace.tsx" />
                <stop offset="1" stopColor="#643800" data-sentry-element="stop" data-sentry-source-file="NeutralFace.tsx" />
            </radialGradient>
            <linearGradient id="paint6_linear_5513_112681" x1="19.98" y1="13.0044" x2="19.98" y2="14.6588" gradientUnits="userSpaceOnUse" data-sentry-element="linearGradient" data-sentry-source-file="NeutralFace.tsx">
                <stop offset="0.00132565" stopColor="#3C2200" data-sentry-element="stop" data-sentry-source-file="NeutralFace.tsx" />
                <stop offset="1" stopColor="#512D00" data-sentry-element="stop" data-sentry-source-file="NeutralFace.tsx" />
            </linearGradient>
            <linearGradient id="paint7_linear_5513_112681" x1="20.8744" y1="11.3128" x2="20.6768" y2="9.78446" gradientUnits="userSpaceOnUse" data-sentry-element="linearGradient" data-sentry-source-file="NeutralFace.tsx">
                <stop offset="0.00132565" stopColor="#3C2200" data-sentry-element="stop" data-sentry-source-file="NeutralFace.tsx" />
                <stop offset="1" stopColor="#7A4400" data-sentry-element="stop" data-sentry-source-file="NeutralFace.tsx" />
            </linearGradient>
            <radialGradient id="paint8_radial_5513_112681" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(15.9996 20.7435) scale(4.0554 0.729567)" data-sentry-element="radialGradient" data-sentry-source-file="NeutralFace.tsx">
                <stop offset="0.00132565" stopColor="#7A4400" data-sentry-element="stop" data-sentry-source-file="NeutralFace.tsx" />
                <stop offset="1" stopColor="#643800" data-sentry-element="stop" data-sentry-source-file="NeutralFace.tsx" />
            </radialGradient>
            <linearGradient id="paint9_linear_5513_112681" x1="10.293" y1="20.4797" x2="21.7063" y2="20.4797" gradientUnits="userSpaceOnUse" data-sentry-element="linearGradient" data-sentry-source-file="NeutralFace.tsx">
                <stop offset="0.00132565" stopColor="#3C2200" data-sentry-element="stop" data-sentry-source-file="NeutralFace.tsx" />
                <stop offset="1" stopColor="#512D00" data-sentry-element="stop" data-sentry-source-file="NeutralFace.tsx" />
            </linearGradient>
        </defs>
    </svg>;
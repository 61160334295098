import React, { FC } from 'react';
import { SpriteIcon } from '../Sprites/SpriteIcon';
import s from './shareLink.module.scss';
import { useShowToast } from '@hooks/useShowToast';
import cn from 'classnames';
import { copyHandler } from '@specs/helpers';
type ShareLinkProps = {
  link: string;
  className?: string;
};
export const ShareLink: FC<ShareLinkProps> = ({
  link,
  className
}) => {
  const {
    showToast
  } = useShowToast();
  const handleCopy = async () => {
    await copyHandler(link).then(_ => {
      showToast({
        variant: 'success',
        text: 'link.copied'
      });
    }).catch(_ => {
      showToast({
        variant: 'error'
      });
    });
  };
  return <SpriteIcon onClick={handleCopy} className={cn(s['share-link'], className)} name="Link_XL" data-sentry-element="SpriteIcon" data-sentry-component="ShareLink" data-sentry-source-file="ShareLink.tsx" />;
};
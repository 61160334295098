import { useCallback } from 'react';
import { api, ReactQueryOptions } from '@api';
import { useShowToast } from '@hooks/useShowToast';
import { useInvalidate } from './useInvalidate';
type optionsType = ReactQueryOptions['BlockManager'];
interface inputType {
  targetShortId: string;
}
export const useBlock = (options?: optionsType) => {
  const {
    showToast
  } = useShowToast();
  const {
    updateFollowData,
    updateBlockedUsersData,
    updatePostsData
  } = useInvalidate();
  const utils = api.useUtils();
  const blockUserMutation = api.BlockManager.blockUser.useMutation({
    mutationKey: ['BlockManager.blockUser'],
    onError: () => {
      showToast({
        variant: 'error',
        text: 'error.default'
      });
    },
    onSuccess: async (_, {
      targetShortId
    }) => {
      const profile = utils.UserManager.getProfile.getData();
      const shortId = profile?.shortid;
      updateFollowData({
        shortId: targetShortId
      });
      updateBlockedUsersData({
        targetShortId: targetShortId ?? ''
      });
      updatePostsData();
      if (typeof shortId === 'string') {
        updateFollowData({
          shortId
        });
      }
      showToast({
        variant: 'success'
      });
    },
    ...options
  });
  const blockUser = useCallback(({
    targetShortId
  }: inputType) => {
    return blockUserMutation.mutateAsync({
      targetShortId
    });
  }, [blockUserMutation]);
  const unblockUserMutation = api.BlockManager.unblockUser.useMutation({
    mutationKey: ['BlockManager.unblockUser'],
    onError: () => {
      showToast({
        variant: 'error',
        text: 'error.default'
      });
    },
    onSuccess: ({
      status,
      data
    }) => {
      const {
        targetShortId
      } = data;
      if (status === 'success') {
        const profile = utils.UserManager.getProfile.getData();
        const shortId = profile?.shortid;
        updateFollowData({
          shortId: targetShortId
        });
        updateBlockedUsersData({
          targetShortId: targetShortId ?? ''
        });
        updatePostsData();
        if (typeof shortId === 'string') {
          updateFollowData({
            shortId
          });
        }
      }
    },
    ...options
  });
  const unblockUser = useCallback((input: inputType) => {
    return unblockUserMutation.mutateAsync(input);
  }, [unblockUserMutation, updateBlockedUsersData]);
  return {
    blockUser,
    unblockUser
  };
};
import { Modal } from '@components/Modals/Modal';
import { useBlockFlow } from '@hooks/useBlockFlow';
import { FC, useMemo } from 'react';
import { BlockStep } from './BlockStep';
import { SuccessStep } from './SuccessStep';
interface IProps {
  onClose: () => void;
  targetShortId: string;
  isOpen: boolean;
}
export const BlockFlow: FC<IProps> = ({
  onClose,
  targetShortId,
  isOpen
}) => {
  const {
    isBlockModalVisible,
    isSuccessModalVisible,
    hideModals,
    handleBlock,
    showSuccessModal
  } = useBlockFlow({
    targetShortId,
    onClose
  });
  const modalComponents = useMemo(() => ({
    block: <BlockStep onSubmit={showSuccessModal} onCancel={hideModals} />,
    success: <SuccessStep onComplete={handleBlock} authorName={targetShortId} />
  }) as const, [handleBlock, hideModals, targetShortId]);
  const renderModal = () => {
    if (isBlockModalVisible) return modalComponents.block;
    if (isSuccessModalVisible) return modalComponents.success;
    return null;
  };
  const handleClose = () => {
    if (isSuccessModalVisible) {
      handleBlock();
    }
    onClose();
    hideModals();
  };
  return <Modal isOpen={isOpen} onClose={handleClose} data-sentry-element="Modal" data-sentry-component="BlockFlow" data-sentry-source-file="BlockFlow.tsx">
			{renderModal()}
		</Modal>;
};
import { api, ReactQueryOptions } from '@api'
import { useShowToast } from '@hooks/useShowToast'
import { ReportReason } from '@prisma/client'
import { useCallback } from 'react'
import { useInvalidate } from './useInvalidate'

type optionsType = ReactQueryOptions['ReportManager']['createReport']

interface ICreateReportProps {
    targetShortId: string
    reason: ReportReason
    postId?: number
    isBlock: boolean
}

export const useReports = (options?: optionsType) => {
    const { showToast } = useShowToast()
    const { updateFollowData, updateBlockedUsersData } = useInvalidate()
    const utils = api.useUtils()

    // create report
    const createReportMutation = api.ReportManager.createReport.useMutation({
        onError: () => {
            showToast({
                variant: 'error',
                text: 'error.default',
            })
        },
        onSuccess: (_, { targetShortId }) => {
            const profile = utils.UserManager.getProfile.getData()
            const shortId = profile?.shortid

            updateFollowData({ shortId: targetShortId })
            updateBlockedUsersData({ targetShortId })
            utils.PostsManager.getPersonalFeed.refetch()
            utils.invalidate()
            utils.FeedManager.invalidate()

            if (typeof shortId === 'string') {
                updateFollowData({ shortId })
            }

            showToast({
                variant: 'success',
            })
        },
        ...options,
    })

    const createReport = useCallback(
        ({ targetShortId, reason, postId, isBlock }: ICreateReportProps) => {
            return createReportMutation.mutateAsync({
                targetShortId,
                reason,
                postId,
                isBlock,
            })
        },
        [createReportMutation],
    )

    return {
        // Mutations
        createReport,
        // Loading states
        isCreatingReport: createReportMutation.isLoading,
        // Error states
        createReportError: createReportMutation.error,
    }
}
